.parameters_step_2 table tr.active {
  background-color: #e8e8e8; }

.parameters_step_2 table tr:hover {
  background-color: #e8e8e8 !important; }

.parameters_step_2 .btn-width-full {
  width: 100%; }

.parameters_step_2 .client-checkboxes .checkbox {
  margin-top: 10px !important; }

.parameters_step_2 .btn-sm {
  padding: 6px 5px 3px 5px;
  font-size: 10px; }

.parameters_step_2 .society {
  margin-left: 20px;
  display: flex; }
  .parameters_step_2 .society .society-name {
    color: #6b6b6b;
    border: 1px solid #bababa;
    border-radius: 5px;
    padding: 3px 7px;
    display: flex;
    align-items: center; }
    .parameters_step_2 .society .society-name .glyphicon {
      font-size: 22px;
      margin-left: 8px; }
  .parameters_step_2 .society .switch {
    margin-left: 20px; }
  .parameters_step_2 .society .question-mark {
    margin-left: 10px;
    margin-top: 4px; }
