.contacts-list .vertical-align-top {
  vertical-align: middle; }

@media screen and (max-width: 768px) {
  .contacts-list .replace_mobile_input {
    margin-top: 20px; } }

.contacts-list .search-contact-list .search-box-content {
  background-color: inherit;
  box-shadow: none;
  padding: 0;
  margin-left: 0;
  margin-bottom: 0; }

.contacts-list a.contact-add-button {
  height: 58px;
  font-size: 1.1em; }

.contacts-list span.remove-contact {
  font-size: 1.5em;
  margin-left: 10px;
  cursor: pointer; }

.contacts-list .mail-contact-list {
  margin-left: 5px; }

.contacts-list .ats-switch {
  width: 80px; }
  .contacts-list .ats-switch .knob {
    transition: width 0.15s ease; }
  .contacts-list .ats-switch .switch-off .knob {
    width: 18%; }
