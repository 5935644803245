.import-modal .fix_center {
  float: none;
  margin: 0 auto;
  text-align: center; }
  .import-modal .fix_center label {
    font-size: 25px; }

.import-modal .zone_checkboxes {
  margin-top: 30px; }
  .import-modal .zone_checkboxes label {
    font-size: 17px;
    text-align: left;
    width: inherit; }
  .import-modal .zone_checkboxes input[type=file] {
    margin: 0 auto;
    margin-top: 40px; }
  .import-modal .zone_checkboxes .import-medias .col-lg-3 {
    width: 100% !important; }
  .import-modal .zone_checkboxes .preview-media {
    margin-top: 20px; }
  .import-modal .zone_checkboxes .file-import-preview {
    background: #e2e2e2;
    width: 50%;
    margin: 0 auto; }

.import-modal .modal-footer {
  text-align: inherit; }
  .import-modal .modal-footer button:first-child {
    float: left; }
  .import-modal .modal-footer button:last-child {
    float: right; }

.import-modal .import-button {
  text-align: center !important;
  display: block !important;
  width: 180px !important;
  margin: 15px auto !important; }
