/*Boostrap*/
img {
  max-width: 100%; }

.contact-profile-form {
  padding: 15px 30px 10px; }
  .contact-profile-form .control-label.grey {
    margin-bottom: 10px; }

.replace-height-select .ui-select-choices {
  z-index: 1000;
  position: relative; }
