/*Boostrap*/
img {
  max-width: 100%; }

.carrier .heading {
  text-align: center; }

.carrier .carrier-info {
  list-style-type: none;
  margin: auto 0;
  padding: 0; }
  .carrier .carrier-info li {
    background: #fff;
    border: 1px solid #e2e2e2;
    margin-top: 4px;
    padding: 5px 4px;
    text-align: center;
    width: 100%; }

.carrier .carrier-info-row > div {
  margin-top: 8px; }

.carrier .dashboard-tab-search .search-box {
  margin: 0; }

.carrier .dashboard-tab-search .search-box-content {
  background: none;
  box-shadow: none;
  margin: 0;
  padding: 0; }

.carrier .refresh-icon {
  color: #104283;
  display: inline-block;
  font-size: .5em;
  margin: auto 0 auto 10px; }

.carrier #carrier-documents-list-table.eqiom-datatable.dataTable tbody td {
  padding: 10px 3px 10px 7px; }

.carrier #carrier-documents-list-table.eqiom-datatable.dataTable .document-download-column {
  padding: 10px 0; }

.research-legend-for-mobile {
  display: none; }

@media screen and (max-width: 992px) {
  .research-legend-for-mobile {
    margin: 0 auto;
    margin-top: 20px;
    display: block; } }
