.btn-decline {
  background-color: #c31310;
  border: 1px solid #770707;
  border-bottom-width: 4px; }

.offer.offer-details .declined-offer-info {
  text-align: center; }
  .offer.offer-details .declined-offer-info span {
    color: #e20011; }

.offer.offer-details .accept-decline-buttons {
  margin-bottom: 50px; }
  .offer.offer-details .accept-decline-buttons .btn {
    margin-top: 15px;
    width: 28%;
    min-width: 180px; }
