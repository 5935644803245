.contact-email-modal .modal-dialog {
  min-width: 600px !important; }

.contact-email-modal .file-import .btn.btn-blue.btn-file:before {
  left: 15%;
  top: 20%; }

.contact-email-modal .max-info {
  display: block;
  font-size: 12px; }
