.absence-box-content {
  margin: 0 auto;
  float: none; }
  .absence-box-content .new_absence_button {
    text-align: center;
    margin: 0 auto;
    float: none; }
    .absence-box-content .new_absence_button .absence-add-button span {
      font-size: 18px;
      font-weight: 500;
      vertical-align: -webkit-baseline-middle;
      position: relative;
      top: 3px; }
    .absence-box-content .new_absence_button .manage_absence {
      text-align: center;
      margin: 0 auto;
      float: none;
      margin-top: 20px; }
      .absence-box-content .new_absence_button .manage_absence span {
        font-weight: bold;
        color: grey;
        font-size: 17px; }
