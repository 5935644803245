/*Boostrap*/
img {
  max-width: 100%; }

.doc .heading {
  text-align: center; }

.doc .carrier-info {
  list-style-type: none;
  margin: auto 0;
  padding: 0; }
  .doc .carrier-info li {
    background: #fff;
    border: 1px solid #e2e2e2;
    margin-top: 4px;
    padding: 5px 4px;
    text-align: center;
    width: 100%; }

.doc .carrier-info-row > div {
  margin-top: 8px; }

.doc .dashboard-tab-search .search-box {
  margin: 0; }

.doc .dashboard-tab-search .search-box-content {
  background: none;
  box-shadow: none;
  margin: 0;
  padding: 0; }

.doc .refresh-icon {
  display: inline-block;
  color: #104283;
  font-size: 0.5em;
  margin: auto 0 auto 10px; }
