@media screen and (max-width: 768px) {
  .col-xs-12.col-sm-9.multiple-checkbox {
    display: block;
    float: none;
    padding: 0px; } }

@media screen and (max-width: 768px) {
  .col-xs-12.col-sm-9.multiple-checkbox .multiple-checkboxes-filter {
    max-width: calc(100% - 30px);
    margin: 0 auto; } }

.col-xs-12.col-sm-9.multiple-checkbox .multiple-checkboxes-filter .checkbox {
  display: inline-block; }
  @media screen and (max-width: 768px) {
    .col-xs-12.col-sm-9.multiple-checkbox .multiple-checkboxes-filter .checkbox {
      display: inline-block;
      float: none;
      width: 100%;
      padding-right: 0px;
      margin-left: 15px; } }
  .col-xs-12.col-sm-9.multiple-checkbox .multiple-checkboxes-filter .checkbox label {
    white-space: nowrap; }
