/*Boostrap*/
img {
  max-width: 100%; }

.social-networks-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: center;
  margin: 0 15px;
  padding: 15px 15px 0 15px; }
  @media (max-width: 768px) {
    .social-networks-block {
      justify-content: flex-start; } }
  .social-networks-block .title {
    font-size: 15px;
    margin: auto 0; }
  .social-networks-block .items {
    display: flex; }
    .social-networks-block .items .social-network {
      padding: 6px; }
      .social-networks-block .items .social-network img {
        height: 30px; }
