/*Boostrap*/
img {
  max-width: 100%; }

.modal-dialog.modal-select-formula {
  min-width: 0 !important; }
  .modal-dialog.modal-select-formula .modal-content {
    border: 2px solid #e2e2e2;
    border-radius: 0;
    box-shadow: none; }
  .modal-dialog.modal-select-formula .modal-body h1 {
    font-size: 1.4em;
    font-weight: normal;
    margin: 5px 0 15px;
    text-align: center; }
  .modal-dialog.modal-select-formula .modal-footer {
    border-top: 0;
    padding-bottom: 20px;
    text-align: center; }
    .modal-dialog.modal-select-formula .modal-footer .btn {
      font-size: 1.1em;
      padding: 8px 35px 7px;
      text-transform: uppercase; }
