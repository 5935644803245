/*Boostrap*/
img {
  max-width: 100%; }

table.eqiom-datatable.dataTable tr.bdl-document-default {
  background-color: #e6f6ff; }
  table.eqiom-datatable.dataTable tr.bdl-document-default.canceled {
    background-color: #ff8181; }
  table.eqiom-datatable.dataTable tr.bdl-document-default.ebm {
    background-color: #94eaa2; }
  table.eqiom-datatable.dataTable tr.bdl-document-default.mortier {
    background-color: #e0e0e0; }
  table.eqiom-datatable.dataTable tr.bdl-document-default.imported {
    background-color: #fef895; }

td.refus_abs {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  text-align: center; }

#carrier-documents-list-table_wrapper {
  padding: 10px 10px 10px 10px; }

table.eqiom-datatable.sm-font thead th {
  padding-right: 0px !important; }

.documents-results-text {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  flex-flow: row wrap;
  padding: 25px;
  background: #e2e2e2;
  text-align: center;
  align-items: center; }
  .documents-results-text .button-group {
    display: flex;
    justify-content: space-between;
    flex-grow: 1; }
    .documents-results-text .button-group .results-button {
      font-size: 11px;
      margin: 0;
      display: block;
      max-width: 110px;
      max-height: 60px; }
  .documents-results-text .results-info {
    display: block;
    flex-grow: 6;
    color: #104283;
    font-size: 1.2em;
    font-weight: 500;
    margin: 10px 0; }
    .documents-results-text .results-info span {
      color: #35a0da;
      font-weight: bold; }
  .documents-results-text research-legend {
    margin-top: 10px;
    flex-grow: 1; }
  @media screen and (max-width: 992px) {
    .documents-results-text .research-legend-for-desktop {
      display: none; } }

.comment.comment-driver {
  background: url("../images/comment.png"); }

.comment.comment-client {
  background: url("../images/comment_orange.png"); }
  .comment.comment-client + .tooltip .tooltip-arrow {
    border-top-color: #fe9b1a; }
  .comment.comment-client + .tooltip .tooltip-inner {
    background-color: #fe9b1a; }
