/*Boostrap*/
img {
  max-width: 100%; }

.bg-white {
  background-color: white; }

.bg-grey {
  background-color: #ededed; }

.display-inline-block {
  display: inline-block; }

.padding-container {
  padding: 20px; }

@font-face {
  font-family: "Gotham-Medium";
  src: url("../fonts/Gotham-Medium.ttf") format("ttf"), url("../fonts/Gotham-Medium.woff") format("woff"); }

@font-face {
  font-family: "Gotham-Book";
  src: url("../fonts/Gotham-Book.ttf") format("ttf"), url("../fonts/Gotham-Book.woff") format("woff"); }

@font-face {
  font-family: "Gotham-Thin";
  src: url("../fonts/Gotham-Thin.ttf") format("ttf"), url("../fonts/Gotham-Thin.woff") format("woff"); }

.login-page {
  font-family: "Gotham-Medium", "Gotham-Book", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .login-page .welcome {
    color: #0C154D; }
    .login-page .welcome .img-max {
      max-width: 500px; }
      @media (max-width: 499px) {
        .login-page .welcome .img-max {
          max-width: 100%; } }
    .login-page .welcome .title {
      font-size: 28px;
      font-weight: bold;
      line-height: 32px; }
      @media (max-width: 768px) {
        .login-page .welcome .title {
          font-size: 20px;
          line-height: 24px; } }
    .login-page .welcome .description {
      font-size: 21px;
      line-height: 25px;
      word-break: break-word; }
      @media (max-width: 768px) {
        .login-page .welcome .description {
          font-size: 15px;
          line-height: 19px; } }
  .login-page .login-btn {
    background-color: #4e73be;
    padding: 5px 30px;
    font-size: 17px;
    border-radius: 10px;
    color: #ffffff;
    display: block;
    border: none; }
  .login-page .feature-title {
    margin: 70px 0 40px 0;
    text-align: center;
    font-weight: 500;
    color: black; }
    @media (max-width: 768px) {
      .login-page .feature-title {
        margin: 40px 0 20px 0; } }
