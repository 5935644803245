.jobsites-list-table .new-chantier-label {
  position: absolute;
  min-width: 60px;
  top: 0;
  left: -29px;
  transform: rotate(270deg);
  background-color: #4e72c7;
  border: 1px solid #54658f;
  color: #FFF;
  font-size: 12px;
  letter-spacing: 0px; }
