/*Boostrap*/
img {
  max-width: 100%; }

galaxie-menu {
  position: relative;
  text-align: center; }
  @media (min-width: 768px) {
    galaxie-menu {
      margin-right: auto; } }
  @media (max-width: 768px) {
    galaxie-menu {
      width: 50%; } }
  @media (max-width: 425px) {
    galaxie-menu {
      width: 100%; } }
  @media (max-width: 768px) {
    galaxie-menu .galaxie-btn {
      width: 100% !important; } }
  galaxie-menu .galaxie-btn i {
    color: #2D70BD;
    font-size: 1.5em; }
  galaxie-menu .galaxie-btn span {
    color: #0C154E;
    font-size: 17px; }
  galaxie-menu .menu-container {
    position: absolute;
    top: -14px;
    height: 70px;
    width: 100%; }
    galaxie-menu .menu-container .menu {
      background-color: #f1f1f1;
      width: 320px;
      position: absolute;
      top: 68px;
      display: flex;
      flex: 1 0 33%;
      justify-content: center;
      text-align: center;
      flex-wrap: wrap;
      border-radius: 3px;
      border: 1px solid #dcdcdc;
      z-index: 999;
      padding: 0; }
      @media (max-width: 425px) {
        galaxie-menu .menu-container .menu {
          width: 100%;
          right: 10px;
          left: 0; } }
      galaxie-menu .menu-container .menu .item {
        width: 90px;
        padding: 11px; }
        galaxie-menu .menu-container .menu .item img {
          width: 56px; }
        galaxie-menu .menu-container .menu .item .name a {
          color: #000000;
          font-size: 17px; }
          galaxie-menu .menu-container .menu .item .name aa:hover {
            text-decoration: underline;
            color: #000000; }
        galaxie-menu .menu-container .menu .item img, galaxie-menu .menu-container .menu .item .name {
          cursor: pointer; }
      galaxie-menu .menu-container .menu.small-grid {
        width: 210px; }
  galaxie-menu .arrow-up {
    position: absolute;
    top: -13px;
    left: 11px;
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid #f1f1f1; }
