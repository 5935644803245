/*Boostrap*/
img {
  max-width: 100%; }

.jobsite-subheader .jobsite-name-header {
  margin-bottom: 0; }
  .jobsite-subheader .jobsite-name-header.sm-font {
    font-size: 1.1em; }
  .jobsite-subheader .jobsite-name-header span {
    display: inline-block;
    font-size: .8em;
    color: #777777; }

.jobsite-subheader .jobsite-agence {
  color: #777777;
  font-size: 16px; }
