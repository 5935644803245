/*Boostrap*/
img {
  max-width: 100%; }

@media (min-width: 768px) {
  .registration-form .row:first-child div[class^='col-']:first-child {
    margin-right: 10px; }
  .registration-form .row:first-child div[class^='col-']:last-child {
    margin-left: 10px; } }

.registration-form .bgwhite {
  padding: 15px 5px;
  border-radius: 0; }

.registration-form-steps .white-background-for-inputs .form-group:not(.no-white-background) {
  padding: 5px 15px;
  border: 1px solid #C1C1C1;
  border-radius: 5px;
  background: #fff; }
  .registration-form-steps .white-background-for-inputs .form-group:not(.no-white-background) > .control-label, .registration-form-steps .white-background-for-inputs .form-group:not(.no-white-background) .input-group > .control-label {
    color: #5DB4E2;
    margin-top: 2px;
    margin-bottom: 4px;
    font-weight: normal;
    display: block; }
  .registration-form-steps .white-background-for-inputs .form-group:not(.no-white-background) .form-control {
    padding-top: 0;
    padding-bottom: 0;
    color: #000;
    max-width: 100%;
    box-shadow: none;
    margin-bottom: 4px;
    background: #fff;
    font-weight: bold;
    min-height: 0;
    border: none;
    border-bottom: 1px solid #d7d7d7;
    border-radius: 0; }
    .registration-form-steps .white-background-for-inputs .form-group:not(.no-white-background) .form-control[type="search"] {
      height: auto;
      padding: 0; }
  .registration-form-steps .white-background-for-inputs .form-group:not(.no-white-background).phone-number-field input::-webkit-input-placeholder {
    font-style: italic; }
  .registration-form-steps .white-background-for-inputs .form-group:not(.no-white-background).phone-number-field input:-moz-placeholder {
    font-style: italic; }
  .registration-form-steps .white-background-for-inputs .form-group:not(.no-white-background).phone-number-field input::-moz-placeholder {
    font-style: italic; }
  .registration-form-steps .white-background-for-inputs .form-group:not(.no-white-background).phone-number-field input:-ms-input-placeholder {
    font-style: italic; }
  .registration-form-steps .white-background-for-inputs .form-group:not(.no-white-background).phone-number-field {
    position: relative; }
  .registration-form-steps .white-background-for-inputs .form-group:not(.no-white-background) .ui-select-bootstrap .form-control {
    min-height: 28px !important; }
  .registration-form-steps .white-background-for-inputs .form-group:not(.no-white-background) .ui-select-bootstrap .caret::before {
    color: #5DB4E2;
    font-size: 1em !important; }
  .registration-form-steps .white-background-for-inputs .form-group:not(.no-white-background) .ui-select-bootstrap .ui-select-match-item {
    margin-bottom: 0 !important; }
  .registration-form-steps .white-background-for-inputs .form-group:not(.no-white-background) .input-group-addon {
    background: inherit; }

@media (max-width: 767px) {
  .registration-form .bgwhite {
    margin: 10px 10px 0 5px; } }
