.offre-step-2 .media-info {
  display: block; }

.offre-step-2 .comment-input-group {
  width: 100%; }

.offre-step-2 .form-min-height {
  min-height: 65px; }
  @media screen and (max-width: 768px) {
    .offre-step-2 .form-min-height {
      margin-top: 20px; } }

.offre-step-2 .preview-media-preview {
  position: relative !important; }
  .offre-step-2 .preview-media-preview .preview-media-remove {
    top: 0; }
    .offre-step-2 .preview-media-preview .preview-media-remove i {
      left: 2px !important; }
