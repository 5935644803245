/*Boostrap*/
img {
  max-width: 100%; }

.maintenance-page {
  padding: 10%;
  text-align: center; }
  .maintenance-page h1 {
    font-size: 3em; }
  .maintenance-page p {
    margin: 0 0 10px; }
