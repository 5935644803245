/*Boostrap*/
img {
  max-width: 100%; }

.performances {
  padding-top: 30px; }
  .performances .charts .is-flex {
    flex-basis: 33.333%; }
  .performances .charts .chart-inner {
    height: 100%;
    margin: auto 0 20px 0;
    width: 100%; }
    .performances .charts .chart-inner p {
      align-self: flex-start;
      color: #777777;
      font-size: 1.2em;
      line-height: 1.1em;
      margin: 40px 0 10px 0;
      min-height: 36px; }
    .performances .charts .chart-inner .chart-container {
      background: #e4e4e4;
      max-height: 390px;
      min-height: 250px;
      padding: 15px;
      width: 100%; }
  .performances .tab-navigation .nav.nav-tabs > li a {
    text-transform: uppercase; }
