/*Boostrap*/
img {
  max-width: 100%; }

form[name="chantier_edit_form"] .form-group {
  margin-bottom: 0; }

@media (min-width: 0) and (max-width: 991px) {
  table#jobsite-home-datatable tbody tr > td:nth-child(1),
  table#jobsite-home-datatable thead tr > th:nth-child(1) {
    display: none; }
  table#jobsite-home-datatable tbody tr > td:nth-child(4),
  table#jobsite-home-datatable thead tr > th:nth-child(4) {
    display: none; }
  table#jobsite-home-datatable tbody tr > td:nth-child(5),
  table#jobsite-home-datatable thead tr > th:nth-child(5) {
    display: none; }
  table#jobsite-home-datatable tbody tr > td:nth-child(6),
  table#jobsite-home-datatable thead tr > th:nth-child(6) {
    display: none; } }

@media (min-width: 0) and (max-width: 991px) {
  jobsite-livraisons table tbody tr > td:nth-child(3),
  jobsite-livraisons table thead tr > th:nth-child(3) {
    display: none; }
  jobsite-livraisons table tbody tr > td:nth-child(5),
  jobsite-livraisons table thead tr > th:nth-child(5) {
    display: none; } }
