@charset "UTF-8";
/*Boostrap*/
img {
  max-width: 100%; }

.preview-media.row.is-flex {
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px; }

.preview-media-element {
  padding: 0; }
  .preview-media-element-inner {
    height: 100%;
    margin: 5px 0 0;
    padding: 0 5px;
    user-select: none; }

.preview-media-remove {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100; }

.preview-media-preview {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #ffffff;
  background-clip: border-box;
  border-radius: 10px;
  padding: 5px 8px;
  height: 100%; }
  .preview-media-preview-icon {
    align-self: center;
    width: 100%;
    font-size: 3em;
    color: #35a0da; }
    @media screen and (max-width: 768px) {
      .preview-media-preview-icon {
        width: auto; } }
  .preview-media-preview-name {
    align-self: flex-end;
    color: #777777;
    width: 100%; }
    .preview-media-preview-name p {
      font-size: 0.9em;
      line-height: 1.2em;
      margin: 0;
      word-wrap: break-word; }

.preview-media-image {
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative; }
  .preview-media-image img {
    min-height: 60px;
    max-height: 180px; }
    .preview-media-image img:after {
      align-self: center;
      background-color: #fff;
      bottom: 0;
      color: #35a0da;
      content: "" " " attr(alt);
      display: block;
      font-family: FontAwesome;
      font-size: 3em;
      height: 100%;
      left: 0;
      min-height: 60px;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 2; }

.file-box .preview-media {
  min-height: 0; }

.modal-open.modal-preview-open .modal-dialog {
  margin: 30px auto 0; }
  .modal-open.modal-preview-open .modal-dialog .modal-content {
    background: none;
    border: 0;
    box-shadow: none;
    display: flex;
    text-align: center; }
    .modal-open.modal-preview-open .modal-dialog .modal-content img {
      margin: auto;
      -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }
