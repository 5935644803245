/*Boostrap*/
img {
  max-width: 100%; }

.search-box {
  margin-left: 0;
  margin-right: 0; }
  .search-box-content {
    background: #d5d5d5;
    box-shadow: inset 0px 2px 7px 3px #c8c8c8;
    padding: 25px 25px 20px 25px;
    margin-bottom: 10px; }
    @media screen and (max-width: 768px) {
      .search-box-content {
        padding-left: 5px;
        padding-right: 5px; } }
    .search-box-content .search-column {
      padding-top: 0; }

.search-filters {
  text-align: center;
  margin-bottom: 15px; }
  .search-filters .checkbox {
    margin-right: 40px; }
    .search-filters .checkbox label {
      color: #777777;
      font-size: 1.1em; }
      .search-filters .checkbox label::before {
        border: 2px solid #747474;
        border-radius: 4px; }
      .search-filters .checkbox label:hover {
        color: #000000; }

.box-content {
  margin-top: 4px;
  background: #fff;
  background-color: #f5f5f5;
  box-shadow: inset 0px 2px 7px 3px #e0e0e0;
  padding: 20px 15px; }
  .box-content .separated-row > div:first-of-type {
    border-right-color: #D4D4D4; }

.documents .dashboard-header {
  margin-bottom: 5px; }

.commercial-deliveries h4, .commercial-offers h4 {
  font-weight: bold;
  margin-bottom: 0; }
  .commercial-deliveries h4 span, .commercial-offers h4 span {
    text-transform: none;
    color: #104283; }

td.bg-offer.bg-offer-restart, .bg-offer-restart.items-count {
  text-shadow: none !important; }

.bg-offer-send {
  color: #f7dc6f; }

.bg-offer-restart {
  color: #ffa500;
  text-shadow: 0 0 0 #717171;
  white-space: nowrap; }

.bg-offer-declined {
  color: #e20011;
  white-space: nowrap; }

.bg-offer-accepted {
  color: #5bae38; }

.bg-offer-renegociate {
  color: #ff8b64; }

.bg-offer-wait {
  color: #DBA901; }

.bg-livraison-error {
  color: #e20011; }
