.footer-new {
  position: relative;
  background-color: #3e434c;
  border-top: 0;
  padding-top: 35px;
  padding-bottom: 36px; }
  .footer-new .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px; }
  .footer-new h2 {
    font-size: 15px;
    color: #ffc31d;
    white-space: nowrap;
    margin-top: 17px;
    margin-bottom: 8.5px;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.1; }
    .footer-new h2:after {
      color: white;
      content: "/";
      display: inline-block;
      font-family: "Gotham-Thin","Gotham-Book","Helvetica Neue",Helvetica,Arial,sans-serif;
      text-align: center;
      width: 16px; }
    .footer-new h2 span {
      white-space: nowrap; }
  .footer-new p {
    margin: 0; }
  .footer-new p:last-child {
    margin-bottom: 0; }
  .footer-new p {
    margin: 0 0 12px; }
  .footer-new a {
    color: white;
    text-decoration: none;
    background-color: transparent;
    font-size: 13px;
    line-height: 1.32857143; }
    .footer-new a:hover {
      text-decoration: underline;
      color: white; }

.credits-wrapper {
  background-color: #272a31;
  color: white;
  padding: 10px 0; }

.credits-wrapper .footer-plan {
  text-align: center; }

.credits-wrapper a {
  color: white;
  font-size: 13.008px;
  background-color: transparent;
  text-decoration: none; }
  .credits-wrapper a:not(:last-child):after {
    content: '|';
    margin: 0 5px;
    color: white;
    display: inline-block; }
  .credits-wrapper a:hover {
    text-decoration: underline;
    color: white; }

@media (min-width: 768px) {
  .credits-wrapper .container, .footer-new .container {
    width: 750px; }
  .credits-wrapper .footer-plan {
    text-align: right; } }

@media (min-width: 992px) {
  .credits-wrapper .container, .footer-new .container {
    width: 970px; } }

@media (min-width: 1200px) {
  .credits-wrapper .container, .footer-new .container {
    width: 1170px; } }
