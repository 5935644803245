/*Boostrap*/
img {
  max-width: 100%; }

.tab-navigation .nav.nav-tabs {
  border: none;
  border-bottom: 1px solid #bfbfbf;
  margin: 10px 0 15px 0; }
  .tab-navigation .nav.nav-tabs > li {
    vertical-align: bottom; }
    .tab-navigation .nav.nav-tabs > li a {
      margin: 0 4px;
      color: #a9b8cc;
      font-size: 1.2em;
      line-height: 1.1em;
      font-weight: bold;
      padding-bottom: 2px; }
      .tab-navigation .nav.nav-tabs > li a, .tab-navigation .nav.nav-tabs > li a:focus, .tab-navigation .nav.nav-tabs > li a:hover {
        border: none;
        border-bottom: 5px solid #dadee3; }
    .tab-navigation .nav.nav-tabs > li.disabled {
      cursor: not-allowed; }
      .tab-navigation .nav.nav-tabs > li.disabled a {
        cursor: not-allowed;
        color: #b7b7b7; }
    .tab-navigation .nav.nav-tabs > li.active a {
      color: #104283;
      background: none;
      border-bottom: 5px solid #104283; }
