/*Boostrap*/
img {
  max-width: 100%; }

.account-container h2 {
  margin: 5px 0 10px;
  font-size: 20px; }
  .account-container h2 span {
    font-size: 20px; }

.account-container .btn-edit {
  bottom: 16px;
  color: #104283;
  display: flex;
  font-size: 1.4em;
  justify-content: center;
  padding: 7px 10px;
  position: absolute;
  right: 24px; }
  .account-container .btn-edit .glyphicon {
    margin: 2px 1px; }

.account-container .content-box {
  margin-top: 15px; }

.account-container .content-box {
  position: relative; }

.account-container input[disabled] {
  background-color: #d5d5d5; }

.account-container .checkboxes .checkbox {
  margin-top: 25px; }

.account-container .checkboxes-status {
  margin-top: -30px;
  padding: 0 17px; }
  .account-container .checkboxes-status .checkbox {
    margin-top: 0; }
    .account-container .checkboxes-status .checkbox label {
      width: 75%; }

.account-container .account-notifications img {
  margin: auto; }

.account-container .account-notifications p {
  font-size: 1.4em; }

.account-container .account-notifications .box-inner {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  user-select: none; }
  .account-container .account-notifications .box-inner.active {
    border: 3px solid #36a1db; }
    .account-container .account-notifications .box-inner.active .box-inner-text {
      color: #35a1db;
      font-weight: bold; }
  .account-container .account-notifications .box-inner .box-inner-text {
    font-size: 1.3em;
    color: #777777; }

.account-container .account-notifications .checkbox label::after {
  color: #35a1db; }

.account-container .account-notifications .notification-column {
  margin-top: 10px; }
  @media (min-width: 992px) {
    .account-container .account-notifications .notification-column .notification-email img {
      margin: auto auto auto 0; }
    .account-container .account-notifications .notification-column .notification-sms img {
      margin: auto 0 auto auto; } }

.account-container .account-favourites .favourite-content {
  border: 1px solid #fff;
  padding: 2px 5px; }
  .account-container .account-favourites .favourite-content h2 {
    margin: 5px 0 16px;
    padding-right: 40px; }

.account-container .account-favourites .favourite-selectable .favourite-content {
  cursor: pointer; }
  .account-container .account-favourites .favourite-selectable .favourite-content:hover {
    border: 1px solid #e2e2e2;
    background: #e7e7e7; }

.account-container .account-packages .packages-row {
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px; }
  .account-container .account-packages .packages-row .package {
    margin: 15px 0 5px; }
    .account-container .account-packages .packages-row .package h4 {
      font-size: 1.7em;
      margin: 8px 0; }
    .account-container .account-packages .packages-row .package p {
      color: #777777;
      font-size: 1.25em;
      line-height: 1.3em; }
    .account-container .account-packages .packages-row .package .box-inner.active {
      background: #fff;
      border: 3px solid #36a1db; }
