.parameters_step_3 {
  margin-top: 15px; }
  .parameters_step_3 table tr.active {
    background-color: #e8e8e8; }
  .parameters_step_3 table tr:hover {
    background-color: #e8e8e8 !important; }
  .parameters_step_3 .calculation {
    color: #1f1f1f;
    font-size: 15px;
    margin: 10px 0 10px 84px;
    font-weight: bold; }
    .parameters_step_3 .calculation .red {
      color: #ea3d3f; }
    .parameters_step_3 .calculation .orange {
      color: #ea7a46; }
    .parameters_step_3 .calculation .green {
      color: #8eea68; }
