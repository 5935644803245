/*Boostrap*/
img {
  max-width: 100%; }

.tooltip {
  min-width: 150px; }

commercial-online-header .navbar .top-navbar .tools a span {
  display: none; }

commercial-online-header #navbar-commercial {
  width: 98%; }

commercial-online-header .navbar {
  background-color: #fff;
  border: 0;
  position: relative; }
  commercial-online-header .navbar .container {
    position: relative; }
  commercial-online-header .navbar .navbar-header .navbar-brand {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 5px 0 4px; }
    @media (min-width: 1546px) {
      commercial-online-header .navbar .navbar-header .navbar-brand {
        position: absolute; } }
    commercial-online-header .navbar .navbar-header .navbar-brand img {
      margin: auto;
      max-width: 125px;
      padding-left: 10px; }
      @media screen and (min-height: 767px) and (max-height: 769px) and (min-width: 1023px) and (max-width: 1025px) {
        commercial-online-header .navbar .navbar-header .navbar-brand img {
          max-width: 100px !important; } }
  commercial-online-header .navbar .navbar-header .navbar-toggle {
    border-color: #35a0da;
    margin-right: 20px; }
    commercial-online-header .navbar .navbar-header .navbar-toggle .icon-bar {
      background-color: #35a0da; }
  commercial-online-header .navbar .top-navbar {
    display: flex;
    width: 100%;
    z-index: 1000;
    flex-flow: row wrap; }
    commercial-online-header .navbar .top-navbar .submenu {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      padding: 0 5px;
      padding-right: 0px; }
      commercial-online-header .navbar .top-navbar .submenu-others, commercial-online-header .navbar .top-navbar .submenu-main {
        position: relative; }
        commercial-online-header .navbar .top-navbar .submenu-others:before, commercial-online-header .navbar .top-navbar .submenu-main:before {
          border-left: 1px solid #c4c4c4;
          bottom: 5px;
          content: "";
          left: 0;
          position: absolute;
          top: 5px;
          width: 1px; }
      commercial-online-header .navbar .top-navbar .submenu-others {
        position: relative;
        margin-left: auto; }
        commercial-online-header .navbar .top-navbar .submenu-others:after {
          border-right: 1px solid #c4c4c4;
          bottom: 5px;
          content: "";
          position: absolute;
          right: 0;
          top: 5px;
          width: 1px; }
      commercial-online-header .navbar .top-navbar .submenu-main {
        justify-content: space-between; }
    commercial-online-header .navbar .top-navbar .item {
      padding: 0 10px;
      margin-left: 0px;
      padding-bottom: 5px;
      padding-top: 5px;
      position: relative; }
      commercial-online-header .navbar .top-navbar .item.active, commercial-online-header .navbar .top-navbar .item:hover {
        background: #e7e7e7; }
        commercial-online-header .navbar .top-navbar .item.active a, commercial-online-header .navbar .top-navbar .item:hover a {
          text-decoration: none; }
        commercial-online-header .navbar .top-navbar .item.active :after, commercial-online-header .navbar .top-navbar .item:hover :after {
          border-bottom: 5px solid #374b96;
          bottom: 2px;
          content: "";
          height: 1px;
          left: 0;
          position: absolute;
          width: 100%; }
      commercial-online-header .navbar .top-navbar .item.item-home a .nav-icon {
        margin: auto 5px; }
        commercial-online-header .navbar .top-navbar .item.item-home a .nav-icon img {
          min-width: 20px; }
      commercial-online-header .navbar .top-navbar .item a {
        align-content: center;
        align-items: center;
        color: #393939;
        display: flex;
        height: 100%;
        justify-content: center;
        line-height: 1.15em;
        min-height: 55px;
        text-align: center;
        width: 100%; }
        commercial-online-header .navbar .top-navbar .item a .nav-icon {
          margin: auto 0; }
          commercial-online-header .navbar .top-navbar .item a .nav-icon img {
            max-height: 28px; }
        commercial-online-header .navbar .top-navbar .item a span {
          font-size: 1.1em;
          font-weight: 600; }

commercial-online-header .item.account span {
  display: none; }

commercial-online-header .item.help span {
  display: none; }

commercial-online-header .item.logout span {
  display: none; }

@media (min-width: 768px) {
  commercial-online-header #navbar {
    margin-left: 137px; }
  commercial-online-header .navbar .navbar-header .navbar-brand img {
    margin: 0;
    padding: 0; }
  commercial-online-header .navbar .navbar-collapse {
    padding: 0 2px; }
  commercial-online-header .container {
    padding: 0; } }

@media (min-width: 768px) {
  commercial-online-header .navbar .navbar-header .navbar-brand img {
    max-width: 115px; }
  commercial-online-header .navbar .top-navbar .item {
    padding: 0 10px; }
    commercial-online-header .navbar .top-navbar .item a .nav-icon {
      /*padding: 0 4px 0 1px;*/ }
  commercial-online-header .navbar .top-navbar .submenu-others > .item {
    padding: 0 5px; }
    commercial-online-header .navbar .top-navbar .submenu-others > .item .nav-icon {
      margin: auto 5px; } }

@media (min-width: 992px) {
  commercial-online-header .container {
    display: flex;
    justify-content: center; }
  commercial-online-header .navbar .top-navbar .item {
    padding: 0 6px;
    max-width: 155px; }
    commercial-online-header .navbar .top-navbar .item a .nav-icon {
      /*padding: 0 5px 0 2px;*/ }
  commercial-online-header .navbar .top-navbar .submenu-others, commercial-online-header .navbar .top-navbar .submenu-main {
    position: relative; }
    commercial-online-header .navbar .top-navbar .submenu-others:before, commercial-online-header .navbar .top-navbar .submenu-main:before {
      border-left: 1px solid #c4c4c4;
      bottom: 5px;
      content: "";
      left: 0;
      position: absolute;
      top: 5px;
      width: 1px; }
  commercial-online-header .navbar .top-navbar .submenu-others {
    position: relative; }
    commercial-online-header .navbar .top-navbar .submenu-others:after {
      border-right: 1px solid #c4c4c4;
      bottom: 5px;
      content: "";
      position: absolute;
      right: 0;
      top: 5px;
      width: 1px; }
  commercial-online-header .navbar .top-navbar .submenu-main {
    justify-content: space-between; }
  commercial-online-header .navbar .top-navbar .submenu-profile {
    display: flex;
    justify-content: flex-end;
    min-width: 45px; } }

@media (min-width: 768px) and (max-width: 1507px) {
  commercial-online-header .navbar .top-navbar .item a span {
    font-size: 0.8em; }
  commercial-online-header .navbar .top-navbar .item.our-offers span {
    -ms-flex-negative: 1;
    display: block;
    padding-bottom: 5px; }
  commercial-online-header .navbar .top-navbar .item.tracking-deliveries span {
    -ms-flex-negative: 1;
    display: block;
    padding-bottom: 5px; }
  commercial-online-header .navbar .top-navbar .item.documents-search span {
    -ms-flex-negative: 1;
    display: block;
    padding-bottom: 5px; } }

@media (min-width: 1200px) {
  commercial-online-header .navbar .top-navbar .item a .nav-icon {
    margin-right: 6px; }
    commercial-online-header .navbar .top-navbar .item a .nav-icon img {
      max-height: 38px; }
  commercial-online-header .navbar .top-navbar .item a span {
    font-size: 0.85em; }
  commercial-online-header .navbar .top-navbar .submenu-main > .item:first-of-type {
    margin-left: 0;
    margin-right: 0px; }
  commercial-online-header .navbar .top-navbar .submenu-main .nav-icon {
    margin: auto 5px; }
    commercial-online-header .navbar .top-navbar .submenu-main .nav-icon img {
      max-width: 36px; }
  commercial-online-header .navbar .top-navbar .submenu-others > .item {
    padding: 0 6px; }
    commercial-online-header .navbar .top-navbar .submenu-others > .item .nav-icon {
      margin: auto 6px; }
  commercial-online-header .navbar .top-navbar .submenu-profile {
    min-width: 60px; } }

@media (min-width: 1508px) {
  commercial-online-header #navbar {
    margin-left: 0; }
  commercial-online-header .navbar .navbar-header {
    bottom: 0;
    left: -150px;
    position: absolute;
    top: 0; }
  commercial-online-header .navbar .top-navbar .item a span {
    font-size: 1.1em;
    font-weight: 600; }
  commercial-online-header .navbar .top-navbar .item.item-home a .nav-icon {
    margin: auto 5px; }
  commercial-online-header .navbar .top-navbar .item.account span {
    min-width: 95px; }
  commercial-online-header .navbar .top-navbar .item.logout a {
    padding: 0 10px; }
    commercial-online-header .navbar .top-navbar .item.logout a .nav-icon {
      margin: auto 0; }
  commercial-online-header .navbar .top-navbar .submenu-main > .item {
    margin: 0 6px; }
    commercial-online-header .navbar .top-navbar .submenu-main > .item:first-of-type {
      margin-left: 0; }
  commercial-online-header .navbar .top-navbar .submenu-others > .item {
    padding: 0 0px; }
    commercial-online-header .navbar .top-navbar .submenu-others > .item .nav-icon {
      margin: auto 10px; } }

@media screen and (max-width: 1200px) {
  commercial-online-header .navbar .top-navbar .submenu-others {
    margin: inherit; }
  commercial-online-header .navbar .top-navbar .item {
    max-width: 126px;
    padding: 0 3px; }
    commercial-online-header .navbar .top-navbar .item a .nav-icon {
      margin-right: 2px; }
    commercial-online-header .navbar .top-navbar .item a span {
      overflow: hidden; }
  commercial-online-header .navbar .top-navbar .submenu-others > .item {
    padding: 0 4px; }
    commercial-online-header .navbar .top-navbar .submenu-others > .item .nav-icon {
      margin: auto 0; } }

commercial-online-header .navbar .top-navbar .item a span {
  font-size: inherit; }

@media screen and (max-width: 992px) {
  commercial-online-header .navbar .top-navbar .tools a span {
    display: block;
    padding-left: 19px !important; }
  commercial-online-header .navbar .top-navbar .item.our-offers span {
    padding-left: 23px; }
  commercial-online-header .item.help span {
    display: block; }
  commercial-online-header .navbar .top-navbar .account a span {
    padding-left: 25px !important; }
  commercial-online-header .item.account span {
    display: block; } }

commercial-online-header .navbar .top-navbar .item a span {
  padding-left: 5px;
  text-overflow: ellipsis;
  white-space: normal; }

@media screen and (max-width: 992px) {
  .item.item-home {
    display: none; }
  commercial-online-header .navbar .top-navbar .submenu-others:after {
    display: none; }
  commercial-online-header .navbar .top-navbar .submenu-main {
    display: block; }
  commercial-online-header .tracking-deliveries a span {
    padding-left: 12px !important; }
  commercial-online-header .documents-search a span {
    padding-left: 31px !important; }
  commercial-online-header .help a span {
    padding-left: 31px !important; }
  commercial-online-header .logout a span {
    padding-left: 36px !important; }
  commercial-online-header .contacts-list a span {
    padding-left: 18px !important; }
  commercial-online-header .navbar .top-navbar .submenu-main:before, commercial-online-header .navbar .top-navbar .submenu-others:before {
    display: none; }
  commercial-online-header .navbar .top-navbar .submenu {
    display: block; }
  commercial-online-header #navbar-commercial {
    max-height: inherit; }
  commercial-online-header .navbar .top-navbar .submenu {
    margin: inherit; }
  .logout a span {
    display: block !important; } }
