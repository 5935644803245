/*Boostrap*/
img {
  max-width: 100%; }

.contact-jobsites-form {
  padding: 25px 30px; }
  .contact-jobsites-form .control-label.grey {
    margin-bottom: 10px; }
  .contact-jobsites-form #contact-jobsites-table_wrapper {
    box-shadow: none;
    margin: 0;
    padding: 0; }
    .contact-jobsites-form #contact-jobsites-table_wrapper .dataTables_info {
      display: none; }
    .contact-jobsites-form #contact-jobsites-table_wrapper table thead > tr > th:last-of-type {
      width: 60px !important;
      text-align: center; }
    .contact-jobsites-form #contact-jobsites-table_wrapper table tbody > tr > td:last-of-type {
      text-align: center; }
      .contact-jobsites-form #contact-jobsites-table_wrapper table tbody > tr > td:last-of-type .glyphicon {
        font-size: 1.5em; }
  .contact-jobsites-form .remove-jobsite-column {
    width: 80px; }
  .contact-jobsites-form .jobsites-select.ui-select-bootstrap .ui-select-choices-row > span {
    padding: 0; }
  .contact-jobsites-form .jobsites-select.ui-select-bootstrap .inner-select {
    padding: 3px 20px; }
  .contact-jobsites-form .ui-select-bootstrap input[type="search"] {
    padding: 15px 5px 13px 21px; }
