/*Boostrap*/
img {
  max-width: 100%; }

.login-new-component {
  background-color: #0d2258;
  color: #ffffff;
  font-size: 13px; }
  .login-new-component .ft-size-22 {
    font-size: 22px; }
  .login-new-component .ft-size-16 {
    font-size: 16px; }
  .login-new-component .login-new-form {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    position: relative;
    width: 100%; }
    .login-new-component .login-new-form .login-new-form-left {
      width: 60%;
      padding: 15px; }
      @media (max-width: 768px) {
        .login-new-component .login-new-form .login-new-form-left {
          width: 100%; } }
    .login-new-component .login-new-form .login-new-form-right {
      width: 40%;
      padding: 30px;
      background-image: url("../images/login-account.png");
      background-repeat: no-repeat;
      background-position: top 15px right 15px;
      display: flex;
      justify-content: center;
      align-items: flex-end; }
      @media (max-width: 1023px) {
        .login-new-component .login-new-form .login-new-form-right {
          background-size: 70%; } }
      @media (max-width: 768px) {
        .login-new-component .login-new-form .login-new-form-right {
          width: 100%;
          background-image: none;
          padding-top: 0; } }
    .login-new-component .login-new-form .form-group {
      display: flex;
      align-items: center;
      padding-bottom: 15px; }
      @media (max-width: 425px) {
        .login-new-component .login-new-form .form-group {
          flex-wrap: wrap; } }
      .login-new-component .login-new-form .form-group .control-label, .login-new-component .login-new-form .form-group .table-cell, .login-new-component .login-new-form .form-group a {
        display: table-cell; }
      .login-new-component .login-new-form .form-group .input-group {
        position: relative;
        display: block;
        width: 75%; }
        @media (max-width: 425px) {
          .login-new-component .login-new-form .form-group .input-group {
            width: 100%; } }
        .login-new-component .login-new-form .form-group .input-group.input-group-user:after {
          position: absolute;
          content: '\f2c0';
          font-family: 'FontAwesome';
          color: #4f4f4e;
          top: 0;
          left: 0;
          bottom: 0;
          width: 30px;
          background-color: #bfbfbf;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
          -webkit-border-radius: 10px 0 0 10px;
          -moz-border-radius: 10px 0 0 10px;
          border-radius: 10px 0 0 10px; }
          @media (max-width: 425px) {
            .login-new-component .login-new-form .form-group .input-group.input-group-user:after {
              margin-left: 0px; } }
        .login-new-component .login-new-form .form-group .input-group.input-group-password:after {
          position: absolute;
          content: '\f09c';
          font-family: 'FontAwesome';
          color: #4f4f4e;
          top: 0;
          left: 0;
          bottom: 0;
          width: 30px;
          background-color: #bfbfbf;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
          -webkit-border-radius: 10px 0 0 10px;
          -moz-border-radius: 10px 0 0 10px;
          border-radius: 10px 0 0 10px; }
          @media (max-width: 425px) {
            .login-new-component .login-new-form .form-group .input-group.input-group-password:after {
              margin-left: 0px; } }
      .login-new-component .login-new-form .form-group input {
        margin-left: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        height: 37px;
        min-height: 0;
        color: #000000;
        max-width: 90%;
        padding-left: 35px;
        outline: none; }
        @media (max-width: 425px) {
          .login-new-component .login-new-form .form-group input {
            margin-left: 0px; } }
      .login-new-component .login-new-form .form-group .control-label {
        font-weight: normal;
        width: 25%;
        text-align: right; }
        @media (max-width: 425px) {
          .login-new-component .login-new-form .form-group .control-label {
            width: 100%;
            text-align: left; } }
      .login-new-component .login-new-form .form-group a {
        color: #DDDDDD;
        text-decoration: underline;
        font-size: 12px; }
  .login-new-component .login-box-image {
    position: absolute;
    right: 43px;
    top: 27px;
    width: 144px; }
    @media (max-width: 1199px) {
      .login-new-component .login-box-image {
        right: 33px;
        top: 17px;
        width: 104px; } }
    @media (max-width: 991px) {
      .login-new-component .login-box-image {
        display: none; } }
  .login-new-component .login-btn-yellow {
    display: inline-block;
    background-color: #ffc31d;
    padding: 10px 20px;
    color: #00194c;
    border: 3px solid #ffc31d;
    border-radius: 7px;
    font-weight: 700;
    font-size: 15px;
    min-width: 160px; }
    .login-new-component .login-btn-yellow:hover {
      text-decoration: underline; }

span.app_version {
  font-size: 14px;
  color: #ededed;
  cursor: default;
  top: 15px;
  left: 15px; }
