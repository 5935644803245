/* kevin@ylly.fr  23/01 */
/*Boostrap*/
img {
  max-width: 100%; }

/* */
/* */
/* ****** OVERRIDE NAVBAR COLLAPSE *** */
@media (max-width: 992px) {
  .navbar-header {
    float: none; }
  .navbar-toggle {
    display: block; }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1); }
  .navbar-collapse.collapse {
    display: none !important; }
  div.navbar-collapse.collapse.in {
    display: block !important;
    padding-top: 15px;
    max-height: inherit; }
  .navbar-nav {
    float: none !important;
    margin: 7.5px -15px; }
  .navbar-nav > li {
    float: none; }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px; }
  .eqiom-beton-app online-header .navbar .top-navbar {
    flex-flow: column wrap !important; }
    .eqiom-beton-app online-header .navbar .top-navbar > div a {
      align-content: flex-start;
      align-items: center;
      color: #393939;
      display: flex;
      height: 100%;
      justify-content: flex-start;
      line-height: 1.15em;
      max-width: 150px;
      min-height: 55px;
      text-align: left;
      width: 100%; }
    .eqiom-beton-app online-header .navbar .top-navbar > div.tools a span br {
      display: none; }
  .eqiom-beton-app commercial-online-header .navbar .top-navbar {
    flex-flow: column wrap !important; }
    .eqiom-beton-app commercial-online-header .navbar .top-navbar > div a {
      align-content: flex-start;
      align-items: center;
      color: #393939;
      display: flex;
      height: 100%;
      justify-content: flex-start;
      line-height: 1.15em;
      max-width: 150px;
      min-height: 55px;
      text-align: left;
      width: 100%; }
    .eqiom-beton-app commercial-online-header .navbar .top-navbar > div.tools a span br {
      display: none; }
  commercial-online-header .navbar .top-navbar .item.active :after, commercial-online-header .navbar .top-navbar .item:hover :after {
    display: none; }
  online-header .navbar .top-navbar > div a .nav-icon {
    max-width: 43px;
    padding: 0 10px; }
  commercial-online-header .navbar .top-navbar .item a span {
    padding-left: 25px; }
  commercial-online-header .navbar .top-navbar > div a {
    max-width: unset !important; }
  online-header .navbar .top-navbar > div a {
    max-width: unset !important; }
  online-header .navbar .top-navbar > div.active :after, online-header .navbar .top-navbar > div:hover :after {
    content: none !important; }
  .top-navbar .performances, .top-navbar .product {
    display: none; } }

@media (max-width: 768px) {
  .inline-buttons > div[class^="col-"] {
    margin-bottom: 15px; }
  .commande .step4 button[type="submit"] {
    margin: 0 !important; }
  div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {
    font-size: 11px; }
  #jobsites-table_wrapper table tbody tr td {
    display: none; }
  #jobsites-table_wrapper table tbody tr td:first-child, #jobsites-table_wrapper table tbody tr td:nth-child(2) {
    display: table-cell; }
    #jobsites-table_wrapper table tbody tr td:first-child a, #jobsites-table_wrapper table tbody tr td:nth-child(2) a {
      font-size: 14px; }
  #jobsites-table_wrapper table thead th {
    display: none; }
  #jobsites-table_wrapper table thead th:first-child, #jobsites-table_wrapper table thead th:nth-child(2) {
    display: table-cell; }
  .home.home_jobsite table.eqiom-datatable thead th:nth-child(3) {
    display: none; }
  .home.home_jobsite table.eqiom-datatable tbody td a {
    font-size: 14px; }
  .home.home_jobsite table.eqiom-datatable tbody td td:nth-child(2) {
    display: none; }
  #orders-shipping-table_wrapper table thead th {
    display: none; }
  #orders-shipping-table_wrapper table thead th:nth-child(2), #orders-shipping-table_wrapper table thead th:nth-child(3), #orders-shipping-table_wrapper table thead th:nth-child(4), #orders-shipping-table_wrapper table thead th:nth-child(7) {
    display: table-cell;
    font-size: 9px; }
  #orders-shipping-table_wrapper table tbody td {
    display: none; }
  #orders-shipping-table_wrapper table tbody td:nth-child(2), #orders-shipping-table_wrapper table tbody td:nth-child(3), #orders-shipping-table_wrapper table tbody td:nth-child(4), #orders-shipping-table_wrapper table tbody td:nth-child(7) {
    display: table-cell;
    font-size: 10px; }
  .home.home_jobsite table.order_detail_table thead th {
    font-size: 9px; }
  .home.home_jobsite table.order_detail_table thead th:nth-child(3) {
    display: none; }
  .home.home_jobsite table.order_detail_table tbody td {
    font-size: 10px; }
  .home.home_jobsite table.order_detail_table tbody td:nth-child(3) {
    display: none; }
  .home.home_jobsite table.livraisons_list thead th {
    display: none; }
  .home.home_jobsite table.livraisons_list thead tH:nth-child(1), .home.home_jobsite table.livraisons_list thead tH:nth-child(2), .home.home_jobsite table.livraisons_list thead tH:nth-child(4), .home.home_jobsite table.livraisons_list thead tH:nth-child(3), .home.home_jobsite table.livraisons_list thead tH:nth-child(7) {
    display: table-cell;
    font-size: 9px; }
  .home.home_jobsite table.livraisons_list tbody td {
    display: none; }
  .home.home_jobsite table.livraisons_list tbody td:nth-child(1), .home.home_jobsite table.livraisons_list tbody td:nth-child(2), .home.home_jobsite table.livraisons_list tbody td:nth-child(4), .home.home_jobsite table.livraisons_list tbody td:nth-child(3), .home.home_jobsite table.livraisons_list tbody td:nth-child(7) {
    display: table-cell;
    font-size: 10px; }
  .home.home_jobsite table#offers-table thead th {
    font-size: 9px; }
  .home.home_jobsite table#offers-table thead th:nth-child(3) {
    display: none; }
  .home.home_jobsite table#offers-table tbody td {
    font-size: 10px; }
    .home.home_jobsite table#offers-table tbody td a {
      font-size: 10px; }
  .home.home_jobsite table#offers-table tbody td:nth-child(3) {
    display: none; }
  .eqiom-beton-app online-header .navbar .top-navbar > div.logout::before {
    content: none; } }

@media screen and (max-width: 480px) {
  a.new-order-button {
    font-size: 18px; }
    a.new-order-button div.plus-circle-icon {
      height: 30px;
      width: 30px; } }

@media screen and (max-width: 430px) {
  a.new-order-button {
    font-size: 14px; }
    a.new-order-button div.plus-circle-icon {
      height: 20px;
      width: 20px; } }

@media screen and (max-width: 630px) {
  .step-navigation p {
    font-size: 10px; } }

@media screen and (max-width: 400px) {
  .step-navigation p {
    display: none; }
  .ui-select-bootstrap .form-control span {
    display: table-cell;
    font-size: 14px; } }
