.eqiom-contact-form .logo, .eqiom-contact-form .tel-info {
  margin-left: 15px;
  margin-bottom: 28px; }

.eqiom-contact-form .tel-info ul {
  margin: 0;
  padding-left: 25px; }

.eqiom-contact-form .tel-info .footer, .eqiom-contact-form .tel-info .or {
  font-size: 0.9em; }

.eqiom-contact-form .tel-info, .eqiom-contact-form .destination {
  font-size: 0.85em; }

.eqiom-contact-form .tel-info-size {
  font-size: 0.85em; }

.eqiom-contact-form .destination {
  margin-bottom: 10px; }
