/*Boostrap*/
img {
  max-width: 100%; }

.step-2 .form-control-quantity {
  border-radius: 5px !important;
  border-right-style: solid !important; }

.step-2 .danger {
  color: #a94442; }

.step-2 .ui-select-choices-row span > span {
  white-space: pre; }

.step-2 .ui-select-choices-row span :nth-child(2) {
  color: red;
  margin-left: 10px; }

.step-2 .messages label {
  color: #a94442; }

.step-2 .schedule-warning {
  position: absolute;
  top: -23px;
  color: #007b9a;
  font-weight: bold; }

.step-2 .additional-questions .custom-observation {
  border-radius: 5px; }

.step-2 .additional-questions .container-full-width {
  width: 100%; }
