/*Boostrap*/
img {
  max-width: 100%; }

.facture_icon {
  width: 28px; }

doc-documents-list td.refus_abs {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  text-align: center; }

doc-documents-list .select-all-centrales {
  width: 80%;
  margin-left: 0px !important;
  font-size: 11px !important; }

doc-documents-list .multiple-checkbox {
  background-color: #c3c3c3;
  padding-left: 45px; }

doc-documents-list hr {
  margin: 15px -25px; }
  doc-documents-list hr.mt0 {
    margin-top: 0; }

doc-documents-list .search-field {
  margin-bottom: 15px; }

@media (min-width: 768px) {
  doc-documents-list hr {
    margin: 15px -15px; }
  doc-documents-list .form-group {
    margin-bottom: 0; }
  doc-documents-list .search-field {
    margin-bottom: 0; } }

@media (min-width: 992px) {
  doc-documents-list hr {
    margin: 15px -50px; } }

doc-documents-list .documents .dashboard-header {
  margin-bottom: 5px; }
  @media screen and (max-width: 768px) {
    doc-documents-list .documents .dashboard-header {
      margin-bottom: 15px; } }

doc-documents-list .search-box {
  margin-left: 0;
  margin-right: 0; }
  doc-documents-list .search-box-content {
    background: #d5d5d5;
    box-shadow: inset 0px 2px 7px 3px #c8c8c8;
    padding: 25px 25px 20px 25px;
    margin-bottom: 10px; }
    doc-documents-list .search-box-content .search-column {
      padding-top: 0; }

doc-documents-list .search-filters {
  text-align: center;
  margin-bottom: 15px; }
  doc-documents-list .search-filters .checkbox {
    margin-right: 40px; }
    doc-documents-list .search-filters .checkbox label {
      color: #777777;
      font-size: 1.1em; }
      doc-documents-list .search-filters .checkbox label::before {
        border: 2px solid #747474;
        border-radius: 4px; }
      doc-documents-list .search-filters .checkbox label:hover {
        color: #000000; }

doc-documents-list .dashboard-tab-search .search-box {
  margin: 0;
  margin-bottom: 20px; }

doc-documents-list .dashboard-tab-search .search-box-content {
  background: none;
  box-shadow: none;
  margin: 0;
  padding: 0; }

@media screen and (min-width: 768px) {
  doc-documents-list .centrales-select {
    margin-left: 1.2em; } }

doc-documents-list .control-label {
  margin-bottom: auto !important;
  margin-left: 0 !important;
  text-transform: none !important; }

doc-documents-list .form-control {
  background-color: #ffffff !important;
  font-size: 1.4em !important;
  padding: 12px 10px 12px 20px !important; }
