.phone-input {
  padding: 5px 15px; }
  .phone-input label.main-label {
    color: #5DB4E2;
    margin: 0;
    margin-top: 2px;
    margin-bottom: 4px;
    font-weight: normal;
    display: block;
    width: 75%;
    vertical-align: middle; }
  .phone-input .country-select {
    display: inline-block;
    width: 29%;
    vertical-align: top;
    color: #757575; }
    .phone-input .country-select input {
      pointer-events: none; }
  .phone-input .phone-number {
    display: inline-block;
    width: 65%; }
    .phone-input .phone-number .flag-container {
      display: none; }
    .phone-input .phone-number .intl-tel-input input {
      padding-left: 5px; }
