/*Boostrap*/
img {
  max-width: 100%; }

.step-navigation {
  margin: 10px 0 30px 0;
  background-color: #f5f5f5;
  background-image: -webkit-linear-gradient(left, #ededed, #ffffff 50%, #ededed);
  background-image: -o-linear-gradient(left, #ededed, #ffffff 50%, #ededed);
  background-image: linear-gradient(to right, #ededed, #ffffff 50%, #ededed);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEDEDED', endColorstr='#FFEDEDED', GradientType=1);
  border-top: 1px #d3d3d3 solid;
  border-bottom: 1px #d3d3d3 solid;
  padding: 5px 0;
  font-size: 1em;
  color: #c7d1de; }
  .step-navigation :first-child img {
    padding-top: 10px; }
  .step-navigation-return {
    margin: auto auto auto -15px; }
  .step-navigation p {
    margin: 2px 0; }
    @media screen and (max-width: 992px) {
      .step-navigation p {
        min-height: 45px; } }
  .step-navigation .custom-col-sm-2 {
    width: 10% !important; }
  .step-navigation .nav-disc {
    border-radius: 10px;
    width: 20px;
    height: 20px;
    margin: auto auto 5px auto;
    display: block;
    background: #c7d1de;
    opacity: 0.5; }
    @media screen and (max-width: 768px) {
      .step-navigation .nav-disc {
        margin: auto auto auto auto; } }
  .step-navigation .active {
    font-weight: bold;
    font-size: 1.12em; }
  .step-navigation .passed, .step-navigation .active {
    color: #104283; }
    .step-navigation .passed .nav-disc, .step-navigation .active .nav-disc {
      background: #104283;
      opacity: 1; }
