.impersonation-alert {
  width: 500px;
  text-align: center;
  padding: 5px;
  margin: 10px auto;
  background-color: #2675a0;
  font-size: 15px;
  border-radius: 3px;
  border: 1px solid #3a3a3a;
  color: #ffffff;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777; }
  .impersonation-alert span {
    animation: blinker 2s linear infinite; }

@keyframes blinker {
  50% {
    opacity: 0; } }
