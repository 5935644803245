/*Boostrap*/
img {
  max-width: 100%; }

registration-link {
  height: 100%;
  border: 3px solid #00194c;
  padding: 40px 30px;
  background-image: url("../images/plus-circle-yellow.png");
  background-repeat: no-repeat;
  background-position: top 10px right 10px;
  background-size: 100px;
  display: flex;
  align-items: center;
  min-height: 250px; }
  @media (max-width: 768px) {
    registration-link {
      background-image: none;
      display: block;
      padding: 10px 15px; } }
  registration-link .register-component {
    margin-top: -30px; }
    @media (max-width: 768px) {
      registration-link .register-component {
        margin-top: 0px; } }
  registration-link .ft-size-22 {
    font-size: 22px; }
  registration-link .ft-size-16 {
    font-size: 16px; }
  registration-link .login-btn-login {
    display: inline-block;
    background-color: transparent;
    padding: 10px 20px;
    color: #00194c;
    border: 3px solid #00194c;
    border-radius: 7px;
    font-weight: 700;
    font-size: 15px;
    min-width: 160px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%); }
    registration-link .login-btn-login:hover {
      text-decoration: underline; }
