/*Boostrap*/
img {
  max-width: 100%; }

.documents-list .document-download-column {
  text-align: center; }

.documents-list .document-icon {
  cursor: pointer; }
  .documents-list .document-icon i {
    font-size: 2em; }

.document-icon {
  cursor: pointer; }
  .document-icon i {
    font-size: 2em; }

.document-list-view {
  margin-top: 35px;
  padding: 15px 15px 10px; }

.documents-results {
  margin-bottom: 20px;
  margin-top: -20px; }

.commercial .heading {
  text-align: center; }

.commercial .dashboard-tab-search .search-box {
  margin: 0;
  margin-bottom: 20px; }

.commercial .dashboard-tab-search .search-box-content {
  background: none;
  box-shadow: none;
  margin: 0;
  padding: 0; }

@media screen and (min-width: 768px) {
  .commercial .centrales-select {
    margin-left: 1.2em; } }
