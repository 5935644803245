.modal-open .contact-email-modal .modal-dialog {
  min-width: 600px !important; }
  @media screen and (max-width: 768px) {
    .modal-open .contact-email-modal .modal-dialog {
      max-width: 90%;
      min-width: inherit !important;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 20px; } }

.modal-open .contact-email-modal .file-import .btn.btn-blue.btn-file:before {
  left: 15%;
  top: 20%; }
  @media screen and (max-width: 768px) {
    .modal-open .contact-email-modal .file-import .btn.btn-blue.btn-file:before {
      left: 2%; } }

@media screen and (max-width: 768px) {
  .modal-open .contact-email-modal .replace_button_mobile {
    text-align: center; } }

@media screen and (max-width: 768px) {
  .modal-open .contact-email-modal .replace_button_mobile .pull-left {
    float: none !important;
    margin-left: 0px !important; } }

@media screen and (max-width: 768px) {
  .modal-open .contact-email-modal .replace_button_mobile .pull-right {
    float: none !important;
    margin: 0px;
    margin-top: 20px; } }

.modal-open .contact-email-modal .max-info {
  display: block;
  font-size: 12px; }
