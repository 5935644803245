/*Boostrap*/
img {
  max-width: 100%; }

#documents-list-bdls-table_wrapper {
  padding: 5px 9px 5px 9px;
  overflow-x: auto; }
  #documents-list-bdls-table_wrapper table.eqiom-datatable.dataTable thead th:not(.select-all) {
    padding: 5px 6px 5px 4px; }

.documents-home .notValidate {
  text-align: center;
  margin-top: 10px; }

.documents-home .select-all-centrales {
  width: 80%;
  margin-left: 0px !important;
  font-size: 11px !important; }

.documents-home .multiple-checkbox {
  background-color: #c3c3c3;
  padding-left: 45px; }

.documents-home .documents-home-search-items {
  align-items: center; }

.documents-home hr {
  margin: 15px -25px; }
  .documents-home hr.mt0 {
    margin-top: 0; }

.documents-home .search-field {
  margin-bottom: 15px; }

@media (min-width: 768px) {
  .documents-home hr {
    margin: 15px -15px; }
  .documents-home .form-group {
    margin-bottom: 0; }
  .documents-home .search-field {
    margin-bottom: 0; } }

@media (min-width: 992px) {
  .documents-home hr {
    margin: 15px -50px; } }
