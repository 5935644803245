/*Boostrap*/
img {
  max-width: 100%; }

doc-online-header li.help {
  margin-top: 16px; }

@media (min-width: 1450px) {
  doc-online-header .navbar .navbar-header .navbar-brand {
    position: absolute; } }
