.registration-requests-table .statut {
  display: inline; }

.registration-requests-table .question-mark {
  display: inline; }

.registration-requests-table .statut-0 {
  color: #fe9b1a; }

.registration-requests-table .statut-1 {
  color: #94eaa2; }

.registration-requests-table .statut-2 {
  color: #e20011; }

.registration-requests-table .research-legend {
  margin: 15px 153px 0 auto;
  flex-wrap: nowrap;
  width: 279px; }
  .registration-requests-table .research-legend .item {
    display: flex; }
    .registration-requests-table .research-legend .item input {
      margin-right: 5px; }
    .registration-requests-table .research-legend .item div.color {
      margin-right: 5px; }

.registration-requests-table .archived-research .research-legend {
  margin: 15px 15px 0 auto; }
