.chantier-success {
  width: 96%;
  margin-left: 24px;
  padding: 15px 15px 10px 15px;
  background: #d4d4d4; }
  @media screen and (max-width: 768px) {
    .chantier-success {
      width: 92%;
      margin-left: 14px; } }
  .chantier-success p {
    font-size: 1.5em;
    margin: 0; }
