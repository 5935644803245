multi-level-select .item {
  margin-bottom: 20px;
  position: relative; }

multi-level-select .remove-row {
  top: 22px;
  left: -13px;
  position: absolute; }

multi-level-select .selected-label {
  font-size: 0.8em;
  max-width: 132px; }

multi-level-select .product-choosed-info {
  display: table;
  min-height: 50px; }
  multi-level-select .product-choosed-info span {
    display: table-cell;
    vertical-align: middle;
    font-weight: bold; }
