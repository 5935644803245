.mass-import-modal .modal-footer {
  text-align: inherit; }
  .mass-import-modal .modal-footer button:first-child {
    float: left; }
  .mass-import-modal .modal-footer button:last-child {
    float: right; }

.mass-import-modal .modal-body {
  display: flex;
  justify-content: center; }

.mass-import-modal .import-button {
  text-align: center !important;
  display: block !important;
  width: 180px !important;
  margin: 15px auto !important; }
