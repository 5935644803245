footer.footer.eqiom-footer {
  height: 38px;
  width: 100%;
  text-align: center;
  background-color: #e4e4e4;
  line-height: 41px;
  font-weight: bold;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px; }

footer.footer.eqiom-footer .l-footer-container > *:not(:last-child):after {
  content: '|';
  margin: 0 5px;
  color: #777;
  display: inline-block; }
