/*Boostrap*/
img {
  max-width: 100%; }

@media (max-width: 425px) {
  .offline-header-login-page-new {
    padding: 0; } }

.offline-header-login-page-new .top-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0; }
  .offline-header-login-page-new .top-bar .top-bar-logo-img {
    display: block; }
    @media (max-width: 425px) {
      .offline-header-login-page-new .top-bar .top-bar-logo-img {
        margin-bottom: 10px; } }
    @media (min-width: 768px) {
      .offline-header-login-page-new .top-bar .top-bar-logo-img {
        margin-right: auto; } }
    @media (max-width: 768px) {
      .offline-header-login-page-new .top-bar .top-bar-logo-img {
        margin: 10px auto; } }
  @media (max-width: 768px) {
    .offline-header-login-page-new .top-bar {
      flex-wrap: wrap;
      justify-content: space-between; } }
  .offline-header-login-page-new .top-bar .btn-margin {
    margin-left: 15px; }
  @media (max-width: 425px) {
    .offline-header-login-page-new .top-bar .margin-button-header, .offline-header-login-page-new .top-bar .header-button, .offline-header-login-page-new .top-bar .espace-client-btn, .offline-header-login-page-new .top-bar .espace-partner-btn, .offline-header-login-page-new .top-bar .help-button {
      margin-bottom: 10px; } }
  .offline-header-login-page-new .top-bar .header-button {
    padding: 6px;
    background-color: #ffffff;
    border: 1px solid #2D70BD;
    outline: none;
    text-transform: uppercase;
    height: 40px; }
    @media (max-width: 768px) {
      .offline-header-login-page-new .top-bar .header-button {
        width: 49%;
        margin-left: 0px; } }
    @media (max-width: 425px) {
      .offline-header-login-page-new .top-bar .header-button {
        width: 100%;
        margin-left: 0px; } }
  .offline-header-login-page-new .top-bar .espace-client-btn {
    background-color: #bfbfbf;
    border: 1px solid #bfbfbf;
    padding: 6px;
    min-width: 180px;
    text-align: center;
    color: #393939;
    position: relative; }
    .offline-header-login-page-new .top-bar .espace-client-btn:hover {
      text-decoration: underline; }
  .offline-header-login-page-new .top-bar .espace-partner-btn {
    background-color: #bfbfbf;
    border: 1px solid #bfbfbf;
    padding: 6px;
    min-width: 180px;
    color: #393939;
    text-align: center;
    position: relative;
    margin-left: 0; }
    .offline-header-login-page-new .top-bar .espace-partner-btn:hover {
      text-decoration: underline; }
  .offline-header-login-page-new .top-bar .active {
    background-color: #00008e;
    color: #ffffff;
    border: none;
    padding: 6px;
    position: relative; }
  .offline-header-login-page-new .top-bar .help-button {
    display: flex;
    justify-content: center;
    align-content: center;
    border: none;
    color: #00008e;
    background-color: #ededed; }
    @media (min-width: 769px) {
      .offline-header-login-page-new .top-bar .help-button {
        margin-left: 25px; } }
    .offline-header-login-page-new .top-bar .help-button img {
      display: block;
      max-width: 20px;
      max-height: 20px; }
    .offline-header-login-page-new .top-bar .help-button a {
      font-size: 14px;
      display: table-cell;
      vertical-align: middle;
      padding-left: 5px;
      color: #1cb9f6; }
      .offline-header-login-page-new .top-bar .help-button a:hover {
        color: #1cb9f6; }
