.contact-societies-modal .nav-icon {
  margin-top: 100px;
  margin-bottom: 10px;
  text-align: center; }

.contact-societies-modal .change_society, .contact-societies-modal .change_agency {
  text-align: center; }

.contact-societies-modal .modal-dialog {
  min-width: 600px !important; }

.contact-societies-modal .file-import .btn.btn-blue.btn-file:before {
  left: 15%;
  top: 20%; }

.contact-societies-modal .max-info {
  display: block;
  font-size: 12px; }
