.ats-switch.triple-toggle-switch {
  width: 211px !important; }
  .ats-switch.triple-toggle-switch .switch-left, .ats-switch.triple-toggle-switch .knob, .ats-switch.triple-toggle-switch .switch-right {
    transition: box-shadow .3s;
    min-height: 20px;
    padding-top: 5px;
    color: #000;
    background: #eeeeee; }
    .ats-switch.triple-toggle-switch .switch-left:not(.knob):hover, .ats-switch.triple-toggle-switch .knob:not(.knob):hover, .ats-switch.triple-toggle-switch .switch-right:not(.knob):hover {
      border: 1px solid #777777;
      border-radius: 3px;
      padding-top: 4px; }
    .ats-switch.triple-toggle-switch .switch-left.active, .ats-switch.triple-toggle-switch .knob.active, .ats-switch.triple-toggle-switch .switch-right.active {
      color: #fff;
      background: #428bca; }
  .ats-switch.triple-toggle-switch .switch-left {
    width: 26% !important; }
  .ats-switch.triple-toggle-switch .switch-right {
    width: 91px !important; }
  .ats-switch.triple-toggle-switch .knob {
    width: 36px !important;
    background-color: #ffffff;
    padding: 6px 0 0 0;
    min-height: 21px; }
    .ats-switch.triple-toggle-switch .knob:hover {
      cursor: default; }
  .ats-switch.triple-toggle-switch:hover {
    border-color: #ccc;
    box-shadow: none; }
  .ats-switch.triple-toggle-switch:focus {
    outline: none; }
