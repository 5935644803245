/*Boostrap*/
img {
  max-width: 100%; }

@media (min-width: 768px) {
  online-header .container {
    padding: 0; }
  online-header .navbar .navbar-collapse {
    padding: 0 15px;
    padding-right: 0px; } }

online-header .navbar {
  background-color: #fff;
  border: 0;
  margin-bottom: 0;
  position: relative; }
  @media (min-width: 1438px) {
    online-header .navbar .navbar-header {
      bottom: 0;
      left: 10px;
      position: absolute;
      top: 0; } }
  online-header .navbar .navbar-header .navbar-brand {
    margin: 0;
    padding: 0 5px 0 4px;
    display: flex;
    align-items: center; }
    @media (min-width: 992px) {
      online-header .navbar .navbar-header .navbar-brand {
        position: absolute;
        left: 10px !important; } }
    @media screen and (max-width: 768px) {
      online-header .navbar .navbar-header .navbar-brand {
        position: relative;
        left: 5px !important;
        top: -10px; } }
    online-header .navbar .navbar-header .navbar-brand img {
      margin: auto;
      max-width: 100px;
      padding-left: 10px;
      position: relative;
      z-index: 99; }
      @media screen and (min-height: 767px) and (max-height: 769px) and (min-width: 1023px) and (max-width: 1025px) {
        online-header .navbar .navbar-header .navbar-brand img {
          max-width: 100px;
          margin-left: 15px !important; } }
    @media (min-width: 768px) {
      online-header .navbar .navbar-header .navbar-brand img {
        margin: 0;
        padding: 0; } }
  online-header .navbar .navbar-header .navbar-toggle {
    border-color: #35a0da;
    margin-right: 20px; }
    online-header .navbar .navbar-header .navbar-toggle .icon-bar {
      background-color: #35a0da; }
  @media (min-width: 992px) and (max-width: 1437px) {
    online-header .navbar #navbar {
      margin-left: 45px !important; } }
  online-header .navbar .top-navbar {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    z-index: 1000; }
    @media (min-width: 992px) {
      online-header .navbar .top-navbar {
        justify-content: space-between; } }
    online-header .navbar .top-navbar > div {
      padding: 0 10px;
      position: relative; }
      online-header .navbar .top-navbar > div:first-of-type {
        margin-left: 0; }
      online-header .navbar .top-navbar > div.active, online-header .navbar .top-navbar > div:hover {
        background: #e7e7e7; }
        online-header .navbar .top-navbar > div.active a, online-header .navbar .top-navbar > div:hover a {
          text-decoration: none; }
        online-header .navbar .top-navbar > div.active :after, online-header .navbar .top-navbar > div:hover :after {
          border-bottom: 5px solid #374b96;
          bottom: 2px;
          content: "";
          height: 1px;
          left: 10%;
          position: absolute;
          width: 80%; }
      @media (max-width: 767px) {
        online-header .navbar .top-navbar > div {
          padding-bottom: 5px;
          padding-top: 5px; } }
      @media (min-width: 992px) {
        online-header .navbar .top-navbar > div.jobsites span {
          display: block;
          max-width: 85px; }
        online-header .navbar .top-navbar > div.practices span {
          display: block;
          max-width: 80px; }
        online-header .navbar .top-navbar > div.logout {
          margin-left: -8px;
          padding: 0 5px; }
          online-header .navbar .top-navbar > div.logout::before {
            content: "";
            display: block;
            width: 1px;
            height: 30px;
            position: absolute;
            background-color: #a0abbd;
            transform: translateY(66%); } }
      online-header .navbar .top-navbar > div a {
        align-content: center;
        align-items: center;
        color: #393939;
        display: flex;
        height: 100%;
        justify-content: center;
        line-height: 1.15em;
        max-width: 150px;
        min-height: 55px;
        text-align: center;
        width: 100%; }
        online-header .navbar .top-navbar > div a .nav-icon {
          padding: 0 10px; }
        @media (max-width: 1199px) {
          online-header .navbar .top-navbar > div a .nav-icon {
            min-width: 43px; } }
  @media screen and (max-width: 1199px) and (min-height: 767px) and (max-height: 769px) and (min-width: 1023px) and (max-width: 1025px) {
    online-header .navbar .top-navbar > div a .nav-icon {
      min-width: 25px;
      width: 25px; } }
        @media (max-width: 1199px) {
            online-header .navbar .top-navbar > div a .nav-icon img {
              display: block;
              max-width: 100%; } }
        online-header .navbar .top-navbar > div a span {
          font-size: 1.1em;
          font-weight: 600;
          -ms-flex-negative: 1;
          word-break: break-word; }
          @media screen and (min-height: 767px) and (max-height: 769px) and (min-width: 1023px) and (max-width: 1025px) {
            online-header .navbar .top-navbar > div a span {
              max-width: 80px; } }
        online-header .navbar .top-navbar > div a .little-name-society-span-parent {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          @media screen and (min-height: 767px) and (max-height: 769px) and (min-width: 1023px) and (max-width: 1025px) {
            online-header .navbar .top-navbar > div a .little-name-society-span-parent {
              max-width: 80px; } }
        online-header .navbar .top-navbar > div a .little-name-society {
          font-size: 8px;
          line-height: 0px; }
      @media (min-width: 768px) and (max-width: 1437px) {
        online-header .navbar .top-navbar > div {
          padding: 0 10px; }
          online-header .navbar .top-navbar > div a .nav-icon {
            padding: 0 4px 0 1px; } }
      @media (min-width: 992px) and (max-width: 1437px) {
        online-header .navbar .top-navbar > div {
          padding: 0 5px; }
          online-header .navbar .top-navbar > div a .nav-icon {
            padding: 0 5px 0 2px; } }
      @media (min-width: 1200px) {
        online-header .navbar .top-navbar > div.logout {
          padding: 0 10px 0 5px; } }
      @media (min-width: 1438px) {
        online-header .navbar .top-navbar > div.jobsites {
          min-width: 150px; }
          online-header .navbar .top-navbar > div.jobsites span {
            max-width: calc(100% - 43px);
            display: block; }
        online-header .navbar .top-navbar > div.tools {
          width: 125px; }
        online-header .navbar .top-navbar > div.performances {
          min-width: 165px; }
        online-header .navbar .top-navbar > div.help {
          margin-right: 35px; }
        online-header .navbar .top-navbar > div.logout {
          margin-left: -52px; }
        online-header .navbar .top-navbar > div a .nav-icon {
          padding: 0 5px 0 10px;
          padding-left: 0px; } }

@media screen and (max-width: 1255px) {
  online-header .navbar .navbar-header .navbar-brand {
    left: 7px; } }

@media screen and (min-width: 992px) and (max-width: 1200px) {
  online-header .navbar .navbar-header .navbar-brand {
    left: 5px !important; }
  online-header .navbar .navbar-header .navbar-brand img {
    width: 90px; }
  online-header .navbar .top-navbar > div {
    max-width: 100px; }
  online-header .navbar .top-navbar > div a span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }

@media screen and (max-width: 768px) {
  commercial-online-header .navbar .navbar-header .navbar-brand img {
    position: absolute;
    top: -10px; }
  commercial-online-header .navbar .top-navbar {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-flow: column wrap !important;
    flex-flow: column wrap !important; } }
