.assist-block {
  text-align: center;
  padding: 15px;
  margin: 15px;
  word-break: break-word; }
  .assist-block img {
    max-width: 100px; }
  .assist-block i {
    margin-top: 15px;
    font-size: 60px;
    color: #29456f; }
