.offre-order-reference {
  padding: 10px 15px;
  background-color: #d3d3d3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.offer-buttons .accept-offer {
  background-color: #d3d3d3;
  padding-bottom: 15px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }
