.medias-list {
  display: flex; }
  .medias-list div {
    padding: 5px; }
  .medias-list span {
    margin: auto auto auto 5px; }

.history-list {
  display: flex;
  flex-direction: column; }
