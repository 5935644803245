/*Boostrap*/
img {
  max-width: 100%; }

.good-practices .content-heading {
  margin-top: 10px; }

.good-practices .header-text {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center; }

.good-practices .tab-navigation {
  margin: 30px 0 8px; }
  .good-practices .tab-navigation .nav.nav-tabs {
    margin: 0; }

.articles-list .panel {
  margin-top: 20px; }

.articles-list .panel-group .panel + .panel {
  margin-top: 15px; }

.articles-list .panel-body {
  border-color: #dcdcdc;
  border-radius: 0 0 5px 5px;
  border-top-color: #c9c9c9;
  margin-top: 1px;
  padding: 25px 30px; }
  .articles-list .panel-body .article-content span {
    color: #69b9e4; }
  .articles-list .panel-body .article-content video {
    max-width: 85%; }
  .articles-list .panel-body .article-content img {
    max-width: 85%; }

.articles-list .panel [role="tab"] {
  background: #fff;
  border-radius: 5px;
  padding: 10px 30px;
  position: relative;
  width: 100%; }
  .articles-list .panel [role="tab"] a {
    display: block;
    padding-right: 30px;
    width: 100%; }
    .articles-list .panel [role="tab"] a:hover {
      text-decoration: none; }
    .articles-list .panel [role="tab"] a::after {
      color: #303030;
      content: '\f078';
      font-family: FontAwesome;
      font-weight: normal;
      position: absolute;
      right: 30px;
      top: 28%;
      display: inline-block;
      vertical-align: middle; }
  .articles-list .panel [role="tab"] span {
    color: #104283;
    font-weight: bold; }

.articles-list .panel-open [role="tab"] {
  background: #104283; }
  .articles-list .panel-open [role="tab"] span {
    color: #fff; }
  .articles-list .panel-open [role="tab"] a::after {
    color: #fff;
    content: ' \f077 '; }

@media screen and (max-width: 768px) {
  .margin-fix-mobile {
    margin-top: 0px !important; } }
