/*Boostrap*/
img {
  max-width: 100%; }

.features-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 15px; }
  .features-list .feature {
    padding: 10px;
    width: 33.334%; }
    @media (max-width: 768px) {
      .features-list .feature {
        width: 50%; } }
    @media (max-width: 425px) {
      .features-list .feature {
        width: 100%; } }
    .features-list .feature .feature-container {
      cursor: pointer;
      position: relative;
      padding: 25px;
      border: 1px solid #4e73be;
      text-align: center;
      word-break: break-word;
      height: 100%; }
    .features-list .feature .small-description {
      font-size: 14px; }
    .features-list .feature img {
      margin: 0 auto; }
    .features-list .feature .feature-popup {
      position: absolute;
      background-color: rgba(219, 227, 241, 0.9);
      max-width: 450px;
      min-width: 400px;
      z-index: 999;
      padding: 20px;
      -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
      outline: none; }
      @media (max-width: 425px) {
        .features-list .feature .feature-popup {
          max-width: inherit;
          min-width: inherit;
          left: 0px !important;
          right: 0px; } }
      .features-list .feature .feature-popup img {
        max-width: 90px; }
      .features-list .feature .feature-popup .name {
        color: #3575bc;
        font-weight: bold; }
      .features-list .feature .feature-popup .close-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px; }
  .features-list .feature-margin {
    margin: 10px 15px; }
