/*Boostrap*/
img {
  max-width: 100%; }

.products-services .tooltip {
  min-width: 400px; }

.products-services .area-info-box h2 {
  margin-bottom: 8px; }

.products-services .tab-products {
  margin-top: 30px; }

.products-services .favourite-column {
  padding: 10px 8px;
  border: 1px solid white; }

.products-services .favourite-selectable {
  cursor: pointer; }
  .products-services .favourite-selectable:hover {
    border: 1px solid #777777;
    background: #e7e7e7; }

.products-services .favourite-list-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  flex-basis: 0; }

.products-services .favourite-list-title {
  margin-right: 6px; }
  .products-services .favourite-list-title p {
    margin: 0; }

.products-services .favourite-list-file-icon {
  margin: 0 0 0 auto; }
  .products-services .favourite-list-file-icon i {
    font-size: 2em;
    margin: 0 10px 0 0;
    display: inline-block;
    cursor: pointer; }
  .products-services .favourite-list-file-icon img {
    display: inline-block; }

.products-services .favourite-list-icon {
  width: 35px;
  margin: 0 5px 0 0; }
  .products-services .favourite-list-icon i {
    font-size: 2em;
    color: #35a0da; }

.products-services .schema-place-point {
  background: red; }
