.form-jobsite-order-reference .preview-media-column {
  position: relative; }

.form-jobsite-order-reference .preview-media-remove {
  position: absolute;
  left: 50px;
  top: 0; }

.form-jobsite-order-reference .file-import .btn.btn-blue.btn-file {
  font-size: 1em;
  text-align: center;
  padding: 4px 25px;
  width: 100%; }
  .form-jobsite-order-reference .file-import .btn.btn-blue.btn-file:before {
    left: 7%;
    top: 20%; }
