.cadre {
  width: 25%;
  padding: 10px;
  display: inline-block;
  border: 3px solid #e2e2e2; }
  .cadre input {
    visibility: hidden; }
  .cadre img {
    margin-right: 5px; }
  .cadre .img_resize {
    width: 37px; }
  .cadre span {
    margin-left: 5px; }

.para_help {
  margin-bottom: 20px; }

.button_submit {
  float: none !important;
  display: block;
  text-align: center;
  margin: 0 auto !important;
  margin-top: 20px !important; }
