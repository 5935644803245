/*Boostrap*/
img {
  max-width: 100%; }

carrier-online-header .navbar, doc-online-header .navbar {
  background-color: #fff;
  border: 0;
  position: relative; }
  carrier-online-header .navbar .contact-form, doc-online-header .navbar .contact-form {
    padding: 19px 10px; }
  carrier-online-header .navbar .container, doc-online-header .navbar .container {
    position: relative; }
  carrier-online-header .navbar .nav.navbar-nav > li:hover, doc-online-header .navbar .nav.navbar-nav > li:hover {
    background: #e7e7e7; }
  carrier-online-header .navbar .navbar-header .navbar-brand, doc-online-header .navbar .navbar-header .navbar-brand {
    margin: 0;
    padding: 0 5px 0 4px; }
    @media (min-width: 1450px) {
      carrier-online-header .navbar .navbar-header .navbar-brand, doc-online-header .navbar .navbar-header .navbar-brand {
        position: absolute; } }
    carrier-online-header .navbar .navbar-header .navbar-brand img, doc-online-header .navbar .navbar-header .navbar-brand img {
      margin: auto;
      max-width: 125px;
      padding-left: 10px; }
      @media (min-width: 992px) {
        carrier-online-header .navbar .navbar-header .navbar-brand img, doc-online-header .navbar .navbar-header .navbar-brand img {
          max-width: 130px;
          position: absolute;
          top: -6px; } }
