.bootstrap-switch {
  border-radius: 2px;
  padding: 1px;
  border: 1px solid #4c4c4c; }
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
    color: #003380;
    background: #003380; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
    color: #dc2424;
    background: #dc2424; }
  .bootstrap-switch .bootstrap-switch-default, .bootstrap-switch .bootstrap-switch-primary {
    border-radius: 0; }
  .bootstrap-switch.bootstrap-switch-supermini .bootstrap-switch-handle-on,
  .bootstrap-switch.bootstrap-switch-supermini .bootstrap-switch-handle-off,
  .bootstrap-switch.bootstrap-switch-supermini .bootstrap-switch-label {
    padding: 1px 3px;
    font-size: 12px;
    line-height: 1.1; }
