/*Boostrap*/
img {
  max-width: 100%; }

@media screen and (max-width: 768px) {
  .weather-icon-right-mobile {
    float: right; } }

.toolbox-container .builder {
  min-height: 400px; }
  .toolbox-container .builder-preview {
    background: #fff;
    height: 100%;
    margin-top: 15px;
    min-height: 250px;
    width: 100%; }
    .toolbox-container .builder-preview-inner {
      height: 100%;
      padding: 10px 15px;
      width: 100%; }
      .toolbox-container .builder-preview-inner canvas#builder {
        width: 500px;
        height: 250px; }
  @media (min-width: 992px) {
    .toolbox-container .builder-elements {
      padding-right: 30px; } }
  .toolbox-container .builder-select-type {
    align-items: center;
    display: flex;
    margin-bottom: 8px; }
    .toolbox-container .builder-select-type-text {
      font-size: 1.5em;
      font-weight: bold;
      margin-right: 5px; }
      .toolbox-container .builder-select-type-text-operation {
        color: #777777;
        font-size: 1em;
        margin-top: -6px; }
    .toolbox-container .builder-select-type .select-type-image {
      margin: auto;
      max-height: 40px; }
    .toolbox-container .builder-select-type-box {
      background: #c7dce8;
      margin: 0 3px;
      min-width: 70px;
      padding: 8px 12px; }
      .toolbox-container .builder-select-type-box i {
        font-size: 2em;
        margin: 4px 25px; }
      .toolbox-container .builder-select-type-box .on {
        display: none; }
      .toolbox-container .builder-select-type-box .off {
        display: block; }
      .toolbox-container .builder-select-type-box-active {
        background: #104283; }
        .toolbox-container .builder-select-type-box-active .on {
          display: block; }
        .toolbox-container .builder-select-type-box-active .off {
          display: none; }
    .toolbox-container .builder-select-type-actions {
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      margin: 0 0 0 auto; }
      .toolbox-container .builder-select-type-actions .btn-remove {
        font-size: 1.6em;
        margin-right: -12px; }
      .toolbox-container .builder-select-type-actions .btn-toggle {
        margin-top: -4px; }
  .toolbox-container .builder-manage-row {
    margin-top: 20px; }
  .toolbox-container .builder-manage-buttons {
    margin-top: 80px; }
  .toolbox-container .builder-total-volume {
    font-size: 1.4em;
    margin-top: 10px; }

.toolbox-weather .weather-alert-box {
  background: #dcdcdc;
  padding: 8px 5px; }

.toolbox-weather .weather-place {
  margin: 25px 0 10px; }

.toolbox-weather .weather-icon, .toolbox-weather .temperature {
  display: inline-block; }

.toolbox-weather .weather-stats {
  color: #777777;
  font-weight: bold; }
  .toolbox-weather .weather-stats strong {
    color: #555555; }

.toolbox-weather .weather-box {
  background: #ffffff;
  margin: 15px 0 5px; }
  .toolbox-weather .weather-box .weather-hourly-lists {
    margin-bottom: 20px; }
  .toolbox-weather .weather-box .weather-stats-box {
    padding-right: 60px;
    position: relative; }
  .toolbox-weather .weather-box .weather-details .weather-chart {
    padding: 10px 0 30px; }
  .toolbox-weather .weather-box.is-collapsed .weather-details {
    border-top: 1px solid #337ab7;
    margin: 5px 10px 0;
    padding-bottom: 10px; }
  .toolbox-weather .weather-box .go-inside {
    bottom: 0;
    margin: auto;
    position: absolute;
    right: 30px;
    top: 0; }
  .toolbox-weather .weather-box .weather-label {
    padding-left: 25px;
    padding-right: 5px; }
    .toolbox-weather .weather-box .weather-label h2 {
      color: #555555;
      font-weight: bold;
      margin: 8px 0 5px 0; }
    .toolbox-weather .weather-box .weather-label h4 {
      color: #777777;
      margin: 0 0 8px; }
    .toolbox-weather .weather-box .weather-label .weather-icon {
      margin: auto; }
  .toolbox-weather .weather-box .weather-stats-row {
    align-content: center;
    align-items: center;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    display: flex;
    height: 85%;
    justify-content: space-between;
    margin: auto 0; }
    .toolbox-weather .weather-box .weather-stats-row .weather-stat-column {
      align-content: center;
      align-items: center;
      display: flex;
      flex-basis: 0;
      flex-grow: 6;
      height: 100%; }
      .toolbox-weather .weather-box .weather-stats-row .weather-stat-column-wind {
        flex-grow: 4; }
      .toolbox-weather .weather-box .weather-stats-row .weather-stat-column-rain {
        flex-grow: 5; }
    .toolbox-weather .weather-box .weather-stats-row > div {
      padding: 10px; }
    .toolbox-weather .weather-box .weather-stats-row .weather-stats {
      margin: auto; }
      .toolbox-weather .weather-box .weather-stats-row .weather-stats-wind {
        padding-bottom: 10px; }
  .toolbox-weather .weather-box .wind-direction {
    font-size: 2em; }
  .toolbox-weather .weather-box .temperature {
    color: #777777;
    font-size: 2em;
    font-weight: bold;
    margin: auto auto auto -10px; }
  .toolbox-weather .weather-box .weather-hourly-view-content {
    border-bottom: 1px solid #d0d0d0;
    padding-bottom: 10px;
    padding-top: 20px; }
  .toolbox-weather .weather-box .weather-hourly-view .label {
    border-radius: 15px; }
  .toolbox-weather .weather-box .weather-hourly-view .weather-description {
    font-style: italic; }
  .toolbox-weather .weather-box .weather-hourly-view .wind-direction {
    font-size: 1em; }

.weather-box-component .btn.weather-box-details {
  display: block;
  font-size: 0.9em;
  font-weight: bold;
  line-height: 0.95em;
  margin: 6px 0 3px 8px;
  white-space: normal;
  width: 56px; }
  @media screen and (min-height: 767px) and (max-height: 769px) and (min-width: 1023px) and (max-width: 1025px) {
    .weather-box-component .btn.weather-box-details {
      width: 45px;
      font-size: 0.7em; } }
  .weather-box-component .btn.weather-box-details .spinner {
    display: block;
    max-width: 100%;
    text-align: center; }

.weather-box-list {
  display: table;
  max-width: 100%;
  overflow-x: auto; }
  @media (min-width: 1200px) {
    .weather-box-list {
      overflow: hidden; } }
  .weather-box-list-item {
    display: table-cell;
    vertical-align: middle;
    margin: 0 4px;
    min-width: 82px; }
    .weather-box-list-item .weather-info {
      display: flex;
      flex-direction: row; }
      .weather-box-list-item .weather-info .weather-details-container {
        font-size: 0.8em;
        display: flex;
        flex-direction: column; }
        .weather-box-list-item .weather-info .weather-details-container .weather-temp {
          font-weight: bold; }
        .weather-box-list-item .weather-info .weather-details-container span {
          display: block; }
    .weather-box-list-item img {
      margin: -8px -4px -8px -6px; }

.weather-box-alert {
  margin: 3px 0 0; }

@media screen and (max-width: 768px) {
  .weather-box-list {
    display: block; } }
