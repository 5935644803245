/*Boostrap*/
img {
  max-width: 100%; }

.reinsurances-block {
  display: flex;
  justify-content: space-between;
  margin: 15px auto;
  padding: 15px; }
  @media (max-width: 768px) {
    .reinsurances-block {
      flex-wrap: wrap; } }
  .reinsurances-block .reinsurance {
    color: #4C4C4C;
    text-align: center;
    padding: 0 52px;
    position: relative;
    width: 25%; }
    @media (max-width: 768px) {
      .reinsurances-block .reinsurance {
        width: 50%;
        margin-bottom: 20px; } }
    @media (max-width: 425px) {
      .reinsurances-block .reinsurance {
        width: 100%; } }
    .reinsurances-block .reinsurance img {
      height: 50px; }
    .reinsurances-block .reinsurance h6 {
      font-size: 13px;
      font-weight: 700; }
    .reinsurances-block .reinsurance:not(:last-child):after {
      content: '';
      height: 50px;
      width: 1px;
      background-color: #eaeaea;
      position: absolute;
      right: 0;
      top: 0; }
