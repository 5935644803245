.registration-step-visa .cgv-label i {
  font-size: 26px;
  margin-left: 10px; }

.registration-step-visa .cgv-label label {
  display: inline;
  width: auto; }

.registration-step-visa .cgv-label .pdf-i:before {
  content: none; }
