/*Boostrap*/
img {
  max-width: 100%; }

.chantier .step-navigation {
  margin-top: 20px; }

.chantier-comment h4 {
  margin: 0;
  font-size: 1em;
  font-weight: bold; }

.chantier-comment p {
  margin: 3px 0 8px 0;
  padding-right: 15%;
  line-height: 1.4em; }

.chantier .centrale-closed {
  text-decoration: blink;
  animation: blinker 2.5s linear infinite; }

@keyframes blinker {
  50% {
    opacity: 0; } }

.chantier-content label {
  font-weight: normal; }

.chantier-content .checkboxes {
  margin-bottom: 25px; }

.chantier-content .form-group.estimated {
  margin-top: 20px; }

.chantier-content h4 {
  color: #555555;
  margin-top: 30px; }

.chantier-content .btn.btn-blue.btn-block {
  margin: 0; }

.chantier-content .field-required-info {
  color: #777777;
  margin-top: 40px; }

.chantier-content .form-step-3 p {
  margin-left: 5px; }
  .chantier-content .form-step-3 p.main-text {
    font-size: 1.3em;
    line-height: 1em;
    padding-right: 15%; }

.chantier-content .form-step-3 h3, .chantier-content .form-step-3 p, .chantier-content .form-step-3 address {
  line-height: 1.17em;
  margin: 0; }

.chantier-content .form-step-3 h3 {
  margin: 0 0 4px;
  font-weight: bold;
  font-size: 1.6em; }

.chantier-content .form-step-3 .centrale-box {
  padding: 10px 25px;
  margin: 8px 0; }
  .chantier-content .form-step-3 .centrale-box hr {
    margin: 8px 0; }

.chantier-content .form-step-5 .chantier-data h3 {
  font-weight: bold;
  margin: 0 0 8px; }

.chantier-content .form-step-5 .chantier-data p {
  margin: 0;
  color: #777777; }
  .chantier-content .form-step-5 .chantier-data p strong {
    font-weight: bold;
    color: #000; }

.chantier-content .form-step-5 .map {
  height: 100%; }
  .chantier-content .form-step-5 .map ng-map {
    width: 100%;
    height: 100% !important; }
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (stroke-color: transparent) {
        .chantier-content .form-step-5 .map ng-map {
          height: 250px !important; } } }

.chantier-content .form-step-5 .chantier-final hr {
  margin: 10px 0; }

.form.form-step-media textarea {
  resize: none;
  font-size: 1.6em; }

.form.form-step-media .form-group {
  margin-bottom: 0; }

.form.form-step-media h3 {
  margin: 0; }

.form.form-step-media p {
  margin: 0; }

#custom-search-input {
  background-color: #fff; }
  #custom-search-input button {
    background: none;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    color: #666666;
    padding: 5px 8px 5px 10px; }
    #custom-search-input button:focus .glyphicon-search, #custom-search-input button:active .glyphicon-search, #custom-search-input button:hover .glyphicon-search {
      color: #35a0da; }
  #custom-search-input .glyphicon-search {
    font-size: 23px; }

.commande .form-step-2 p .desired-qty {
  font-size: 1.4em; }

.commande .form-step-2 textarea {
  margin-top: 25px;
  resize: none; }

.commande .form-step-2 hr {
  margin-bottom: 25px; }

.commande .form-group.services {
  padding-bottom: 15px; }
  .commande .form-group.services .combined-inputs {
    margin-top: 15px; }

.commande .form-group .combined-inputs .form-control {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  width: 90px; }

.commande .form-group .combined-inputs .input-group-addon {
  padding-left: 4px; }

.commande .form-group .combined-inputs .combined-label {
  color: #777777;
  font-size: 1.4em;
  font-weight: normal;
  padding: 10px 0 5px; }

.commande .form-group .combined-inputs .or-column p {
  font-size: 1.2em;
  margin: 0 0 3px; }

.commande .form-group .combined-inputs .row > div {
  margin-bottom: auto;
  margin-top: auto; }
  .commande .form-group .combined-inputs .row > div .form-group {
    margin-bottom: auto;
    margin-top: auto; }

@media (min-width: 768px) {
  .commande .quantity-row > div:first-of-type {
    padding-right: 0; }
  .commande .quantity-row > div:not(:first-of-type) {
    padding-left: 5px; } }

@media (min-width: 768px) and (max-width: 1199px) {
  .commande .cubature-addon {
    font-size: .8em; } }

@media (min-width: 768px) {
  .commande .quantity-row > div:first-of-type {
    padding-right: 0; }
  .commande .quantity-row > div:not(:first-of-type) {
    padding-left: 5px; } }

.commande .checkboxes-inline-list {
  margin-bottom: 5px;
  margin-top: 5px; }
  .commande .checkboxes-inline-list .checkbox {
    font-size: 1.1em;
    line-height: 1.1em;
    margin: auto 0;
    padding-bottom: 10px;
    width: 100%; }
    .commande .checkboxes-inline-list .checkbox.opt_1 {
      max-width: 140px; }
    .commande .checkboxes-inline-list .checkbox.opt_3 {
      max-width: 200px; }
    .commande .checkboxes-inline-list .checkbox [for="opt_2"] {
      margin-top: 12px; }

.offers-content .form-step-2 textarea {
  margin-top: 10px; }

.offers-content .type-column p {
  font-size: 1.25em;
  margin: auto 0; }

.offers-content .medias {
  margin-bottom: 5px; }

.offers-content .form-step-3 p {
  color: #777777;
  margin: 0; }
  .offers-content .form-step-3 p strong {
    color: #000;
    font-weight: bold; }

.orders-shipping h4 {
  color: #777777;
  font-weight: normal; }
  .orders-shipping h4 strong {
    color: #000; }

.orders-shipping .tab-navigation ul > li a {
  text-transform: uppercase; }

@media (min-width: 992px) {
  .livraison-details .centered-padding {
    padding-left: 85px; } }

.livraison-details h3 {
  color: #777777;
  font-size: 1.6em;
  font-weight: bold;
  margin: 0 0 10px; }
  .livraison-details h3 strong {
    color: #333333; }

.livraison-details ul {
  list-style: none;
  padding: 0;
  font-size: 1.2em;
  line-height: 1.3em; }
  .livraison-details ul li {
    color: #777777; }
    .livraison-details ul li strong {
      color: #555555;
      font-weight: bold; }

.jobsite-order-reference {
  margin-top: 10px;
  margin-bottom: -5px;
  align-items: flex-start;
  justify-content: center; }
  .jobsite-order-reference .form-control {
    margin: 2px 0;
    min-height: 52px; }
  .jobsite-order-reference .refcmd-column {
    margin-right: auto; }
    .jobsite-order-reference .refcmd-column .form-group {
      margin-bottom: 0; }
