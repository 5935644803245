/*Boostrap*/
img {
  max-width: 100%; }

.dashboard .offer-margin {
  margin-top: 30px; }

.dashboard .livraisons-margin {
  margin-top: 0 !important; }

.dashboard-header {
  display: flex;
  align-items: center;
  height: 35px;
  margin-bottom: 10px; }
  .dashboard-header img {
    margin: -4px 10px 0 20px; }
    @media screen and (max-width: 768px) {
      .dashboard-header img {
        margin: 0px; } }
  .dashboard-header span {
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1.1em;
    font-size: 1.4em;
    margin: 6px 0 4px 5px; }
    @media screen and (max-width: 768px) {
      .dashboard-header span {
        font-size: 1.1em; } }
    .dashboard-header span.sub-info {
      font-size: 1.2em;
      margin-left: 5px;
      color: #777777; }

.dashboard-tab {
  margin-top: 30px; }
  .dashboard-tab-content {
    margin-top: 4px;
    background: #fff;
    background-color: #f5f5f5;
    box-shadow: inset 0px 2px 7px 3px #e0e0e0;
    display: flex;
    align-items: center;
    padding: 20px 15px;
    overflow-x: auto; }
  .dashboard-tab-search .dashboard-header {
    justify-content: center; }
    @media screen and (max-width: 768px) {
      .dashboard-tab-search .dashboard-header {
        margin-bottom: 20px; } }
    .dashboard-tab-search .dashboard-header span {
      margin-top: auto; }
  .dashboard-tab-search .search-box {
    margin-top: 5px; }

.dashboard-navigate {
  margin-left: auto;
  margin-right: 20px; }
  .dashboard-navigate .btn.btn-blue {
    padding: 3px 25px 1px 25px;
    font-size: 0.95em; }

.dashboard-meteo-component {
  width: 100%; }
  .dashboard-meteo-component .weather-box-component {
    width: 100%; }
  .dashboard-meteo-component .weather-box-list {
    float: left !important;
    justify-content: space-between; }
    .dashboard-meteo-component .weather-box-list .btn.btn-blue.btn-sm-blue.btn-weather.weather-box-details {
      background: white;
      color: #00008e;
      font-weight: bold;
      line-height: 1.1em;
      border-radius: 4px;
      border-bottom-width: 1px;
      padding: 5px 5px 3px 5px; }

.dashboard-table-list {
  margin: 10px 0;
  width: 100%;
  font-size: 1.3em; }
  .dashboard-table-list .view-column {
    width: 180px;
    text-align: center;
    font-style: italic; }
  .dashboard-table-list .items-count {
    font-weight: bold;
    margin: 4px 5px 0 5px;
    font-size: 1.2em; }
  .dashboard-table-list > tbody > tr:nth-of-type(odd) {
    background-color: #F0F0F0; }
    .dashboard-table-list > tbody > tr:nth-of-type(odd) .view-column a {
      color: #2a3957 !important; }
  .dashboard-table-list > tbody > tr:nth-child(even) .view-column a {
    color: #3694b7 !important; }
  .dashboard-table-list > tbody > tr:last-of-type td {
    border-bottom: 1px solid #ddd; }
  .dashboard-table-list > tbody > tr td {
    vertical-align: middle; }
    .dashboard-table-list > tbody > tr td img {
      vertical-align: top; }

.dashboard .counter {
  color: #498fd0; }
