.bl-settings .ats-switch {
  width: 120px;
  font-size: 8px; }
  .bl-settings .ats-switch .knob {
    transition: width 0.15s ease; }
  .bl-settings .ats-switch .switch-on .knob {
    width: 28%; }
  .bl-settings .ats-switch .switch-on .switch-left {
    width: 39%; }
  .bl-settings .ats-switch .switch-off .knob {
    width: 18%; }

.parameters-header {
  display: flex;
  position: relative;
  text-align: left;
  margin-top: 12px;
  color: #6b6b6b;
  font-size: 21px;
  align-items: baseline; }
