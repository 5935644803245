.block-picker {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .block-picker .element {
    cursor: pointer;
    background-color: #dfdfdf;
    border: 2px solid #fbfbfb;
    border-radius: 5px;
    padding: 15px;
    font-size: 22px;
    color: #7f7f7f; }
    .block-picker .element.active {
      border: 3px solid #2273b3;
      font-weight: bold;
      padding: 12px 12px; }
