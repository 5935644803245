.operator_parameters_step_1 {
  margin-top: 15px;
  padding-top: 20px; }
  .operator_parameters_step_1 table tr.active {
    background-color: #e8e8e8; }
  .operator_parameters_step_1 table tr:hover {
    background-color: #e8e8e8 !important; }
  .operator_parameters_step_1 .description {
    font-size: 18px;
    padding: 0 0 20px 0; }
  .operator_parameters_step_1 .society {
    font-size: 17px;
    display: flex; }
    .operator_parameters_step_1 .society .society-name {
      color: #1a1a1a;
      align-items: center;
      padding-left: 5px; }
