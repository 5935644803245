.files-importer .heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .files-importer .heading .heading-label {
    width: 100%;
    margin: 20px 20px 20px 0; }

.files-importer .files {
  margin: 0 20px 0 0;
  border: 1px dashed #ababab;
  border-radius: 5px;
  padding: 5px 12px 10px 12px; }
