form[name="chantierStep1"] .form-group .input-group .input-group-addon.cubature-addon {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-left-style: solid;
  border-right-style: solid; }
  form[name="chantierStep1"] .form-group .input-group .input-group-addon.cubature-addon:before {
    display: none; }

form[name="chantierStep1"] .form-group .input-group .form-control.form-control-quantity {
  border-right-style: solid;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px; }

form[name="chantierStep1"] .form-group.estimated .form-control {
  border-right-style: solid;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px; }

form[name="chantierStep1"] .form-group.estimated .volume {
  padding-left: 5px; }
