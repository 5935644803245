/*Boostrap*/
img {
  max-width: 100%; }

online-header {
  background-color: #fff; }

.jobsite-menu .menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 1030;
  top: 121px;
  left: 0; }
  .jobsite-menu .menu .pad.pad-active {
    display: none; }
  .jobsite-menu .menu li {
    display: flex;
    font-size: 10px;
    font-weight: bold;
    height: 80px;
    position: relative;
    text-transform: uppercase;
    width: 100px; }
    .jobsite-menu .menu li:not(:last-of-type) {
      box-shadow: inset 0 -10px 15px -5px #153763;
      filter: progid:DXImageTransform.Microsoft.Shadow(color=#153763, Direction=180, Strength=10); }
    .jobsite-menu .menu li a {
      align-items: center;
      bottom: 0;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 0;
      line-height: 12px;
      position: absolute;
      right: 0;
      top: 0; }
      .jobsite-menu .menu li a .pad {
        margin: 5px 10px 10px 5px; }
      .jobsite-menu .menu li a:hover, .jobsite-menu .menu li a:active {
        text-decoration: none; }
      .jobsite-menu .menu li a span {
        width: 82px;
        display: block; }
        .jobsite-menu .menu li a span.home {
          max-width: 80px; }
    .jobsite-menu .menu li:hover, .jobsite-menu .menu li:focus, .jobsite-menu .menu li.active {
      background-color: white;
      box-shadow: none;
      opacity: 0.95; }
      .jobsite-menu .menu li:hover .pad, .jobsite-menu .menu li:focus .pad, .jobsite-menu .menu li.active .pad {
        display: none; }
      .jobsite-menu .menu li:hover .pad-active, .jobsite-menu .menu li:focus .pad-active, .jobsite-menu .menu li.active .pad-active {
        display: block; }
      .jobsite-menu .menu li:hover a, .jobsite-menu .menu li:focus a, .jobsite-menu .menu li.active a {
        color: #4d4d4c; }
    .jobsite-menu .menu li.active {
      padding-right: 12px;
      width: 112px; }
      .jobsite-menu .menu li.active a {
        right: 12px; }
      .jobsite-menu .menu li.active:not(:last-of-type) a {
        border-bottom: 1px solid #eeeeee; }
      .jobsite-menu .menu li.active:not(:first-of-type) a {
        border-top: 1px solid #eeeeee; }

.btnjh_home {
  background-color: #104283; }

.btnjh_orders {
  background-color: #104283; }

.btnjh_offers {
  background-color: #195a99; }

.btnjh_perfo {
  background-color: #2271ae; }
  .btnjh_perfo.disabled {
    background-color: #eaeaea; }
    .btnjh_perfo.disabled a span {
      color: #909090; }

.btnjh_doc {
  background-color: #2c88c4; }

.btnjh_share {
  background-color: #35a0da; }

.op:hover {
  filter: alpha(opacity=0.8);
  opacity: 0.8; }

.jobsite-menu-mobile {
  background-color: #35a0da;
  display: none;
  height: 35px;
  left: 0;
  padding: 10px;
  position: fixed;
  top: 75px;
  width: 45px;
  z-index: 99; }
  @media screen and (max-width: 1365px) {
    .jobsite-menu-mobile {
      display: block; } }
  .jobsite-menu-mobile span.bar {
    background-color: white;
    display: block;
    height: 3px;
    margin-bottom: 3px;
    width: 25px; }
  .jobsite-menu-mobile .active span {
    position: absolute;
    top: 15px;
    transform-origin: 50% 50%; }
    .jobsite-menu-mobile .active span:first-child {
      transform: rotate(45deg); }
    .jobsite-menu-mobile .active span:nth-child(2) {
      transform: rotate(-45deg); }

@media screen and (max-width: 1365px) {
  .jobsite-menu .menu {
    top: 118px; }
  jobsite-subheader h1 {
    padding-top: 45px; } }

@media screen and (max-width: 992px) {
  .jobsite-menu-mobile {
    display: none; }
  .jobsite-menu.ng-hide {
    display: block !important;
    margin: auto;
    padding: 0 30px;
    width: 100%;
    margin-bottom: 20px; }
    .jobsite-menu.ng-hide .menu {
      bottom: 0;
      left: 0;
      position: relative;
      right: 0;
      top: 15px; }
      .jobsite-menu.ng-hide .menu li {
        display: block;
        text-align: center; }
        .jobsite-menu.ng-hide .menu li.active {
          padding-right: 0; }
          .jobsite-menu.ng-hide .menu li.active a {
            right: 0; }
  .jobsite-menu {
    position: relative; }
    .jobsite-menu .owl-stage {
      margin: auto; }
    .jobsite-menu .owl-nav .owl-prev,
    .jobsite-menu .owl-nav .owl-next {
      position: absolute;
      top: 0;
      bottom: 0;
      cursor: pointer; }
      .jobsite-menu .owl-nav .owl-prev::after,
      .jobsite-menu .owl-nav .owl-next::after {
        font-family: FontAwesome;
        position: relative;
        display: block;
        margin: 100% 0 0;
        font-size: 2em; }
      .jobsite-menu .owl-nav .owl-prev.disabled,
      .jobsite-menu .owl-nav .owl-next.disabled {
        display: none; }
    .jobsite-menu .owl-nav .owl-prev {
      left: -25px; }
      .jobsite-menu .owl-nav .owl-prev::after {
        content: '\f053';
        margin-right: 100%; }
    .jobsite-menu .owl-nav .owl-next {
      right: -25px; }
      .jobsite-menu .owl-nav .owl-next::after {
        content: '\f054'; }
    .jobsite-menu .owl-carousel .owl-item {
      margin-bottom: 15px;
      text-align: center; }
      .jobsite-menu .owl-carousel .owl-item li {
        width: 100%; }
        .jobsite-menu .owl-carousel .owl-item li a {
          padding: 0 5px; }
      .jobsite-menu .owl-carousel .owl-item img {
        margin: auto;
        width: auto; }
  jobsite-subheader h1 {
    padding-top: 5px; } }

@media (min-width: 1159.75px) and (max-width: 1199px) {
  .jobsite-menu-mobile {
    display: none; }
  .jobsite-menu.ng-hide {
    display: block !important; }
  jobsite-subheader h1 {
    padding-top: 0; } }

@media (min-width: 1359.75px) {
  .jobsite-menu-mobile {
    display: none; }
  .jobsite-menu.ng-hide {
    display: block !important; }
  jobsite-subheader h1 {
    padding-top: 0; } }
