.gallery-container {
  border: 1px solid #eaeaea;
  padding-bottom: 15px;
  margin-left: 5%;
  margin-top: 20px;
  margin-bottom: 20px; }
  .gallery-container .images {
    display: flex;
    flex-flow: row wrap; }
    .gallery-container .images img {
      max-width: 100%; }
    .gallery-container .images div {
      margin-top: 15px;
      flex: auto; }
  .gallery-container .button_import {
    width: 20%;
    float: right; }

.alert_max_files {
  color: mediumvioletred;
  text-align: center;
  display: block; }

.document-icon.pdf-base .document-icon {
  display: inline; }

.documents-bdl-modal-container {
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
