.error-messages {
  color: firebrick;
  margin-top: 10px; }

.step-1-group-commande .messages {
  color: firebrick; }

.step-1-group-commande .after-sixteen {
  color: red;
  font-size: 12px; }

.step-1-group-commande .form-group {
  margin-bottom: 5px; }

.step-1-group-commande table tr.header td {
  font-weight: bold;
  color: #000000;
  font-size: 12px;
  vertical-align: middle; }

.step-1-group-commande table td {
  padding: 3px;
  vertical-align: top; }
  .step-1-group-commande table td .small-input, .step-1-group-commande table td.small-input {
    width: 90px; }
  .step-1-group-commande table td .btn.btn-default.form-control.ui-select-toggle {
    font-size: 1.1em; }
  .step-1-group-commande table td.order-datepicker {
    width: 250px; }
    .step-1-group-commande table td.order-datepicker input {
      font-size: 13px; }
  .step-1-group-commande table td.action {
    vertical-align: middle; }
