/*Boostrap*/
img {
  max-width: 100%; }

.c-login-logo {
  margin-top: 130px;
  margin-bottom: 25px; }

.c-login-button {
  margin-top: 15px; }
