/*Boostrap*/
img {
  max-width: 100%; }

.contact-data {
  padding: 25px 30px; }
  @media screen and (max-width: 768px) {
    .contact-data {
      padding: 0px; } }
  .contact-data .checkbox.checkbox-circle.checkbox-md.checkbox-inline {
    margin-top: 0px;
    margin-left: 15px; }
  .contact-data .identity-row {
    margin: 0 auto; }
  .contact-data .form-center-margin {
    margin: 0 auto;
    float: none; }
  .contact-data .form-group {
    padding: 5px 15px;
    border: 1px solid #C1C1C1;
    border-radius: 5px;
    background: #fff; }
    .contact-data .form-group > .control-label {
      color: #5DB4E2;
      margin-top: 2px;
      margin-bottom: 4px;
      font-weight: normal; }
    .contact-data .form-group .form-control {
      padding: 0;
      color: #000;
      max-width: 100%;
      box-shadow: none;
      margin-bottom: 4px;
      background: #fff;
      font-weight: bold;
      min-height: 0;
      border: none;
      border-bottom: 1px solid #d7d7d7;
      border-radius: 0; }
      .contact-data .form-group .form-control[disabled] {
        background-color: transparent; }
      .contact-data .form-group .form-control[type="search"] {
        height: auto;
        padding: 0; }
    .contact-data .form-group.phone-number-field input::-webkit-input-placeholder {
      font-style: italic; }
    .contact-data .form-group.phone-number-field input:-moz-placeholder {
      font-style: italic; }
    .contact-data .form-group.phone-number-field input::-moz-placeholder {
      font-style: italic; }
    .contact-data .form-group.phone-number-field input:-ms-input-placeholder {
      font-style: italic; }
    .contact-data .form-group.phone-number-field {
      position: relative; }
  .contact-data .ui-select-bootstrap .form-control {
    min-height: 28px; }
  .contact-data .ui-select-bootstrap .caret::before {
    color: #5DB4E2;
    font-size: 1em; }
  .contact-data .ui-select-bootstrap .ui-select-match-item {
    margin-bottom: 0; }
  .contact-data .label-inline {
    display: inline;
    font-weight: normal;
    color: #5DB4E2; }

@media screen and (max-width: 768px) {
  .contact-data .col-xs-12.col-sm-6.checkboxes.not-clickable {
    margin-bottom: 20px; }
  .text-align-center-mobile {
    text-align: center !important; } }
