/*Boostrap*/
img {
  max-width: 100%; }

.research-legend {
  width: 250px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .research-legend {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      display: inline-block; } }
  .research-legend .horizontal-line {
    display: block;
    width: 200px;
    height: 1px;
    margin: 0 auto 5px;
    border-bottom: #5d5d5d 1px dashed; }
    @media screen and (max-width: 768px) {
      .research-legend .horizontal-line {
        display: none; } }
  .research-legend .item {
    flex-grow: 1;
    text-align: left;
    min-width: 50%; }
    @media screen and (max-width: 768px) {
      .research-legend .item {
        text-align: center;
        max-width: 40%;
        display: inline-block;
        float: left; } }
    .research-legend .item .color {
      display: inline-block;
      height: 18px;
      width: 18px;
      border: 2px solid #ffffff;
      border-radius: 5px; }
      .research-legend .item .color.default {
        background-color: #e6f6ff; }
      .research-legend .item .color.canceled {
        background-color: #ff8181; }
      .research-legend .item .color.ebm {
        background-color: #94eaa2; }
      .research-legend .item .color.mortier {
        border: none;
        text-align: center;
        color: darkgrey;
        font-size: 18px;
        background-color: #e0e0e0; }
      .research-legend .item .color.imported {
        background-color: #fef895; }
    .research-legend .item label {
      font-size: 15px;
      margin: 0; }
