.no_hidden_modal_body .modal-body .hidden-browser {
  display: none !important; }

.no_hidden_modal_body .modal-footer .button-cgu-edge {
  display: none !important; }

.hidden_modal_body .modal-content {
  height: auto !important; }
  .hidden_modal_body .modal-content .modal-body iframe {
    display: none !important; }
  .hidden_modal_body .modal-content .modal-body .hidden-browser {
    width: 90%;
    margin: 0 auto; }
    .hidden_modal_body .modal-content .modal-body .hidden-browser u {
      color: #337ab7; }
  .hidden_modal_body .modal-content .modal-footer .button-cgu-classic {
    display: none !important; }
