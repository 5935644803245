@charset "UTF-8";
body.modal-open {
  padding-right: 0 !important; }

#cookieModal {
  margin: 0 auto; }

.no-mb {
  margin-bottom: 0 !important; }

eqiom-rgpd .not-clickable {
  opacity: 0.4;
  pointer-events: none; }

eqiom-rgpd .modal-header, eqiom-rgpd .modal-body {
  padding: 0; }

eqiom-rgpd .modal-content {
  padding: 30px; }
  eqiom-rgpd .modal-content .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    eqiom-rgpd .modal-content .modal-footer:before, eqiom-rgpd .modal-content .modal-footer:after {
      display: none; }
  eqiom-rgpd .modal-content .btn-parameters {
    background-color: #5bc0de;
    border: none;
    border-radius: 20px;
    padding: 7px 15px;
    color: white;
    text-transform: uppercase;
    outline: none; }
  eqiom-rgpd .modal-content .btn-disabled {
    background-color: transparent;
    border: none;
    padding: 7px 15px;
    text-decoration: underline;
    color: black;
    text-transform: uppercase;
    outline: none; }

eqiom-rgpd .modal-content-body {
  margin-top: 30px; }

eqiom-rgpd .modal-body {
  color: #676767;
  display: flex;
  width: 100%; }
  eqiom-rgpd .modal-body .checkbox-gdpr {
    margin-right: 10px; }
  eqiom-rgpd .modal-body .modal-body-single {
    margin-right: 10%;
    font-weight: 400; }
    eqiom-rgpd .modal-body .modal-body-single h4 {
      margin-top: 0px; }
    eqiom-rgpd .modal-body .modal-body-single p {
      margin-bottom: 0; }

eqiom-rgpd .read-more-container input[type="checkbox"] {
  position: absolute;
  opacity: 0; }
  eqiom-rgpd .read-more-container input[type="checkbox"]:checked ~ p {
    max-height: 500px; }
  eqiom-rgpd .read-more-container input[type="checkbox"]:checked ~ ul {
    max-height: 500px; }
  eqiom-rgpd .read-more-container input[type="checkbox"]:checked ~ label:after {
    transform: rotate(-90deg); }

eqiom-rgpd .read-more-container label {
  text-decoration: underline;
  position: relative;
  color: #676767; }
  eqiom-rgpd .read-more-container label:after {
    position: absolute;
    content: '›';
    right: -10px;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(90deg); }

eqiom-rgpd .read-more-container p {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
  color: #676767; }

eqiom-rgpd .read-more-container ul {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
  color: #676767; }

eqiom-rgpd .modal.in .modal-dialog {
  min-width: 70% !important;
  height: 200px;
  background-color: whitesmoke; }

eqiom-rgpd .btn-conf {
  position: fixed;
  right: 0;
  bottom: 0;
  border-radius: 5px 0 0 0;
  font-size: 14px;
  padding: 5px 10px;
  background-color: #35a0da; }
  eqiom-rgpd .btn-conf i {
    margin-left: 5px; }

.modal-footer {
  margin: 0 auto;
  text-align: center !important; }

.mfspan {
  color: black !important; }

button {
  border-radius: 5px; }

.polit {
  text-align: center; }

.card button:hover {
  background-color: white; }
