/*Boostrap*/
img {
  max-width: 100%; }

.products-services .tab-navigation .nav.nav-tabs > li a {
  text-transform: uppercase; }

.products-services .header-text {
  color: #555555;
  font-size: 1em;
  text-align: center; }

.products-services .area-info-box {
  margin-top: 10px; }
  .products-services .area-info-box h2 {
    font-size: 1.4em;
    margin: 0; }
    .products-services .area-info-box h2 .badge {
      font-size: .9em;
      margin-top: -3px; }

.products-services .tab-products .home-schema {
  position: relative; }

.products-services .put-on-img {
  color: #777777;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  line-height: .9em;
  max-width: 80px;
  position: absolute;
  text-align: center; }
  .products-services .put-on-img span {
    font-size: 1.1em;
    margin-bottom: 4px; }
  .products-services .put-on-img .badge {
    background-color: #35a0da;
    display: inline-block; }
  .products-services .put-on-img.active {
    color: #222222; }
    .products-services .put-on-img.active .badge {
      background-color: #000;
      box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.45); }

@media (max-width: 991px) {
  .products-services .items-tab-1 .item-2 {
    margin-left: -12px;
    margin-top: 8px; }
  .products-services .items-tab-1 .item-3 {
    margin-top: -20px; }
  .products-services .items-tab-3 .item-5 {
    margin-top: -11px; }
  .products-services .put-on-img .badge {
    font-size: 2.4em;
    padding: 4px 12px; } }

@media (max-width: 767px) {
  .products-services .draggable-icon {
    position: relative;
    text-align: right;
    width: 100%; }
    .products-services .draggable-icon::after {
      bottom: 0;
      color: #777777;
      content: '\f047';
      font-family: FontAwesome;
      font-size: 1.4em;
      position: absolute;
      right: 15px; }
  .products-services .schema-preview-column {
    overflow: scroll; }
    .products-services .schema-preview-column .home-schema {
      width: 768px;
      max-width: 100%; } }
