/*Boostrap*/
img {
  max-width: 100%; }

.offer-heading-status {
  text-align: center; }

.offer hr.top-line {
  margin: 0 -10px 0 -10px; }

.offer hr.content-line {
  margin: 25px -10px 20px -10px; }

.offer .box-content {
  margin-top: 20px; }

.offer-client-info {
  border-top: 1px solid #C9C9C9;
  background-color: #D5D5D5;
  padding: 15px 25px; }
  .offer-client-info .bgwhite {
    padding: 8px 15px;
    width: 100%;
    height: 100%; }
  .offer-client-info .information-list > li {
    font-weight: 600;
    font-size: 1.1em;
    color: #4B4B4B; }
    .offer-client-info .information-list > li strong {
      color: #969696; }

.offer-client-name h2, .offer-client-name h3 {
  margin: 0;
  font-weight: bold; }

.offer-client-name h2 {
  margin-top: 15px;
  color: #F6F6F6;
  font-size: 1.8em; }

.offer-client-name h3 {
  color: #4B4B4B;
  font-size: 2em; }

.offer h4 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px; }
  .offer h4 span {
    font-weight: normal;
    color: #535353; }

.offer h5 {
  font-size: 1.2em;
  margin-bottom: 15px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase; }

.offer .file-import {
  background: none;
  border: 4px dashed #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .offer .file-import > p {
    margin: 2px 0;
    font-size: 1.3em;
    font-weight: bold;
    color: #0F3D7B; }
    .offer .file-import > p.or {
      font-size: 1.2em;
      color: #35a0da;
      font-weight: normal;
      margin-top: -4px; }
  .offer .file-import.dragover {
    border: 4px dashed #35a0da;
    background: #FFFFFF; }
  .offer .file-import .btn.btn-blue.btn-file {
    padding: 7px 15px 4px 15px;
    font-size: 1.2em; }
    .offer .file-import .btn.btn-blue.btn-file:before {
      display: none; }
    .offer .file-import .btn.btn-blue.btn-file span {
      color: #ffffff; }

.offer .media-preview-box .preview-media-preview {
  background: none; }
  .offer .media-preview-box .preview-media-preview img {
    border: 2px solid #D4D4D4; }

.offer-accepted h4, .offer-declined h4 {
  text-transform: none;
  font-weight: normal; }

.offer-details h4 {
  margin-top: 30px; }
  .offer-details h4 strong {
    font-weight: bold;
    font-size: 1.1em; }

.commercial-offers {
  position: relative; }
  .commercial-offers .return-arrow {
    position: absolute; }
