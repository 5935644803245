.offre-step-1 .selectize-control {
  border: 1px solid #bfbfbf;
  border-radius: 5px 0 0 5px; }
  .offre-step-1 .selectize-control .selectize-input {
    border: none; }
    .offre-step-1 .selectize-control .selectize-input .item {
      width: 90%; }
  .offre-step-1 .selectize-control .selectize-dropdown {
    width: 500px !important; }

.offre-step-1 .offer-type {
  text-align: left; }

.offre-step-1 .product-footnote {
  text-align: left;
  padding-left: 0; }

.offre-step-1 .add-product-row {
  font-size: 12px;
  margin-top: 20px; }

.offre-step-1 .input-group-select {
  position: relative; }
  .offre-step-1 .input-group-select .remove-option {
    position: absolute;
    top: calc(50% - 11px);
    z-index: 1; }
    .offre-step-1 .input-group-select .remove-option i {
      font-size: 24px;
      color: #337ab7 !important; }
    .offre-step-1 .input-group-select .remove-option-formule {
      right: 142px; }
    .offre-step-1 .input-group-select .remove-option-service {
      right: 38px;
      z-index: 5; }

.offre-step-1 .remove-formule-row {
  top: 22px;
  left: -13px;
  position: absolute; }

.offre-step-1 .messages label {
  color: #a94442; }

.offre-step-1 .glyphicon-remove::before {
  color: #337ab7 !important; }

.offre-step-1 .waiting-for-response {
  padding: 15px; }

.display_formula_mobile_tablet {
  display: none; }

@media screen and (max-width: 992px) {
  .display_formula_mobile_tablet {
    display: inline-block; }
  .col-xs-12.col-sm-5.col-md-4.hidden_formula_mobile_tablet {
    display: none !important; }
  .margin_display_mobile_tablet {
    margin: 0px !important; } }
  @media screen and (max-width: 992px) and (max-width: 768px) {
    .margin_display_mobile_tablet {
      padding-top: 20px; } }

@media screen and (min-height: 767px) and (max-height: 769px) and (min-width: 1023px) and (max-width: 1025px) {
  .display_formula_mobile_tablet {
    display: inline-block; }
  .col-xs-12.col-sm-5.col-md-4.hidden_formula_mobile_tablet {
    display: none !important; }
  .margin_display_mobile_tablet {
    margin: 0px !important; } }
