.search-box-separated .search-filters {
  text-align: left; }
  @media screen and (max-width: 768px) {
    .search-box-separated .search-filters:nth-child(1) .checkbox.checkbox-inline {
      margin-right: 0px;
      margin-left: 0px; } }
  @media screen and (max-width: 768px) {
    .search-box-separated .search-filters:first-child {
      text-align: center; } }
  .search-box-separated .search-filters .checkbox {
    margin-bottom: 5px; }
    .search-box-separated .search-filters .checkbox.checkbox-inline {
      margin-left: 0;
      margin-right: 40px; }
      @media screen and (max-width: 768px) {
        .search-box-separated .search-filters .checkbox.checkbox-inline {
          margin-right: 0px;
          margin-left: 20px;
          margin-bottom: 20px; } }
