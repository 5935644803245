.invoices-list table.documents-invoices th:nth-child(1), .invoices-list table.documents-invoices td:nth-child(1) {
  max-width: 50px !important;
  text-align: center; }

.invoices-list .documents-results-text .button-group {
  flex-grow: 1; }

.invoices-list .documents-results-text .results-info {
  flex-grow: 16; }

.account-invoices .search-column {
  margin: 0 auto;
  float: none; }
